import { Modal as ModalAD } from "antd";
import { ButtonProps } from "antd/lib/button";
import * as React from "react";
import styled from "styled-components";

import { COLORS } from "../constants";

import { Button } from "./Button";
import { DotsLoader } from "./DotsLoader";

interface ModalProps {
  buttonLayout?: "horizontal" | "vertical";
  color?: "blue" | "white";
  cancelText?: React.ReactNode | string;
  cancelButtonProps?: ButtonProps;
  className?: string;
  closable?: boolean;
  confirmLoading?: boolean;
  cancelLoading?: boolean;
  maskClosable?: boolean;
  okButtonProps?: ButtonProps;
  okText?: React.ReactNode | string;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  onSubmit?: (e: React.MouseEvent<HTMLElement>) => void;
  submitText?: React.ReactNode | string;
  showOk?: boolean;
  showCancel?: boolean;
  showSubmit?: boolean;
  title?: React.ReactNode | string;
  visible: boolean;
  width?: number | string;
  cancelButton?: React.ReactNode;
  okButton?: React.ReactNode;
  submitButton?: React.ReactNode;
  submitStyles?: React.CSSProperties | undefined;
}

const StyledModal = styled(ModalAD)`
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 0px;
      padding: 24px 24px 8px;

      .ant-modal-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .ant-modal-body {
      font-size: 16px;
      color: ${COLORS.black45};
      padding: 8px 24px 24px;
    }

    .ant-modal-footer {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: none;
      display: flex;
      padding: 5px 24px 12px;
      justify-content: space-between;

      .btn-wrap {
        display: flex;

        &.cancel {
          text-align: left;
        }

        &.submit {
          displat: flex;
          align-items: center;
          justify-content: center;
        }

        &.ok {
          text-align: right;
        }

        a {
          font-size: 16px;
          font-weight: 500;
          color: ${COLORS.coeblue4};
        }

        &.cancel a {
          font-weight: 600;
        }
      }
    }
  }

  &.vertical-layout {
    .ant-modal-content .ant-modal-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 24px 24px;
      text-align: center;

      .btn-wrap {
        a {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0;
          color: ${COLORS.coeblue4};
        }

        &.ok {
          width: 100%;
          border-radius: 4px;
          background-color: ${COLORS.coeblue4};
          border-color: ${COLORS.coeblue4};
          text-align: center;
          padding: 7px 10px;
          margin-bottom: 15px;

          a {
            color: ${COLORS.white};
          }
        }
      }
    }
  }

  &&.redOk {
    .btn-wrap {
      &.ok {
        a {
          color: ${COLORS.red};
        }
      }
    }
  }
`;

export const ConfirmBox: React.FC<ModalProps> = ({
  children,
  buttonLayout = "horizontal",
  color = "white",
  closable = false,
  confirmLoading = false,
  cancelLoading = false,
  onSubmit,
  okButtonProps,
  showCancel = true,
  showOk = true,
  showSubmit = false,
  maskClosable = false,
  cancelButton,
  okButton,
  submitText = "Submit",
  submitButton,
  submitStyles = {},
  ...props
}) => {
  return (
    <StyledModal
      {...props}
      maskClosable={maskClosable}
      className={buttonLayout === "vertical" ? "vertical-layout" : ""}
      closable={closable}
      width={props.width || 260}
      footer={[
        <>
          {showCancel && props.onCancel && (
            <div className="btn-wrap cancel" onClick={!cancelLoading ? props.onCancel : () => {}}>
              {cancelButton ?? (
                <Button type="text" noPadding disabled={cancelLoading}>
                  {cancelLoading ? <DotsLoader size="large" /> : props.cancelText || "Cancel"}
                </Button>
              )}
            </div>
          )}
        </>,

        <>
          {showOk && props.onOk && (
            <div className="btn-wrap ok" onClick={!confirmLoading ? props.onOk : () => {}}>
              {okButton ?? (
                <Button type="primary" disabled={confirmLoading} {...okButtonProps}>
                  {confirmLoading ? (
                    <DotsLoader color="white" size="large" />
                  ) : (
                    <>{props.okText || "Ok"}</>
                  )}
                </Button>
              )}
            </div>
          )}
        </>,

        <>
          {buttonLayout === "horizontal" && showSubmit && onSubmit && (
            <div className="btn-wrap submit">
              {submitButton ?? (
                <Button type="text" onClick={onSubmit} style={submitStyles}>
                  {submitText}
                </Button>
              )}
            </div>
          )}
        </>,
      ]}
    >
      {children}
    </StyledModal>
  );
};
