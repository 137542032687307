import { InfoCircleFilled } from "@ant-design/icons";
import { DataSource } from "@coeff/api";
import { useQueryClient } from "@tanstack/react-query";
import { Radio, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { QueryCacheKey } from "../../api";
import { useDomainUsers } from "../../api/users";
import { COLORS } from "../../constants";
import { AppState } from "../../store";
import {
  AssetsHost,
  getUserEmail,
  isValidEmail,
  pluralize,
  stringSearch,
  useApiContext,
} from "../../utils";
import { AutoCompleteTagsInput } from "../AutoCompleteTagsInput";
import { Button } from "../Button";
import { Card } from "../Card";
import { Checkbox } from "../Checkbox";
import { ConfirmBox } from "../ConfirmBox";
import { BulletCheck, SharedUser } from "../Icons";
import { message } from "../message";
import { BulletList, BulletList2, Highlight } from "../misc";
import { Switch } from "../Switch";
import { Tooltip } from "../Tooltip";

interface ShareConnectionCardProps {
  dataSource?: DataSource;
  isShared?: boolean;
  onChange?: (arg: boolean) => void;
  onEdit?: (arg: boolean) => void;
}

const StyledDomainDiv = styled.div`
  min-width: 150px;
`;

const StyledRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  gap: 6px;

  .selectionTitle {
    color: ${COLORS.coeblue4};
  }

  .underline {
    padding-right: 16px;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }
`;

const StyledPanel = styled.div`
  margin-top: 6px;
  padding: 4px;
  color: ${COLORS.black85};

  &:focus,
  &:active {
    outline: none;
  }

  .coeff__card_header {
    .row {
      height: auto;
      line-height: 1.25;
    }
  }
`;

const { Title } = Typography;

const FlexRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorDiv = styled.div`
  color: red;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const OverflowYScroll = styled.div`
  overflow-y: scroll;
`;

const StyledDiv = styled.div`
  padding: 8px;

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    padding-right: 16px;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }
`;

const CardPanel = styled.div`
  margin-left: 20px;

  button {
    background: none;
    border: none;
    font: inherit;
    color: ${COLORS.coeblue4};
    font-weight: 600;
    cursor: pointer;
  }
`;

const LearnMoreModal = styled.div`
  color: ${COLORS.black85};
  padding-top: 16px;

  .center {
    text-align: center;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;

  .styledSvg {
    margin-top: 2px;
    margin-left: 4px;
  }

  .styledToogle {
    margin-top: 4px;
    margin-right: 4px;
    margin-left: auto;
  }
`;

const WarnPanel = styled.div`
  padding: 10px;

  .header {
    display: flex;
    line-height: 20px;

    .sectionIcon {
      color: ${COLORS.orange};
      margin-right: 5px;
    }

    .title {
      flex: 1;
      font-weight: bold;
    }
  }
  .body {
    margin-top: 8px;
    margin-left: 20px;
    line-height: 18px;
  }
`;

const ButtonSection = styled.section<{ noBoxShadow?: boolean }>`
  width: 100%;
  display: flex;
  position: sticky;
  bottom: 0px;
  padding: 20px 4px 10px;
  justify-content: center;
  z-index: 25;
  ${({ noBoxShadow }) => {
    if (noBoxShadow) {
      return "";
    }

    return "box-shadow: 0px -10px 10px -10px #dddddd;";
  }}

  .ant-btn {
    flex: 1;
    margin: 0px 10px;
    max-width: 200px;

    svg {
      width: 1rem;
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
  }
`;

const StyledAutoCompleteTagsInput = styled(AutoCompleteTagsInput)`
  flex-direction: column;
  padding: 6px 12px;
  overflow-x: hidden;
  width: 242px;

  input {
    flex: unset;
    overflow-x: hidden;
  }
`;

const WarnSection = styled.section`
  margin-top: 15px;
  background: ${COLORS.orangeBg};
  border-radius: 5px;
`;

const DeleteCheckbox = styled(Checkbox)`
  margin: 20px 0px 0px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

export const ShareConnectionCard = ({ dataSource, onChange, onEdit }: ShareConnectionCardProps) => {
  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const [showWarning, setShowWarning] = useState(false);

  const [showLearnMore, setShowLearnMore] = useState(false);

  const [showShareRadioButtons, setShowShareRadioButtons] = useState(false);

  const [hasError, setHasError] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showUserListModal, setShowUserListModal] = useState(false);

  const [unshareConfirmed, setUnshareConfirmed] = useState(false);

  const [isSharedWithDomain, setIsSharedWithDomain] = useState(
    dataSource?.is_shared_with_domain || false
  );

  const [isSharedWithUser, setIsSharedWithUser] = useState(!!dataSource?.shared_users_email);

  const [filteredDomainUsersList, setFilteredDomainUsersList] = useState<{ value: string }[]>([]);

  const [unshareConfirmHighlight, setUnshareConfirmHighlight] = useState(false);

  const [sharedUsers, setSharedUsers] = useState<string[] | undefined>(
    dataSource?.shared_users_email
  );

  const [searchValue, setSearchValue] = useState<string>("");

  const { domains, clientFlags } = useSelector((state: AppState) => {
    return {
      domains: state.app.domains_in_domain_family,
      clientFlags: state.app.clientFlags,
    };
  });

  const { data: domainUsersList, isLoading } = useDomainUsers();

  // const { domains_in_domain_family: domains, clientFlags } = useRootContext();

  const updateConnectionSharing = async (
    isSharedWithDomainVar: boolean,
    sharedUsersVar: string[] | undefined
  ) => {
    if (!dataSource || !dataSource.data_source_id) {
      return;
    }

    if (!isSharedWithDomainVar && sharedUsersVar?.length === 0) {
      setHasError(true);
      setErrorMessage("Add at least one user");
      return;
    }

    const dataSourceCopy: DataSource = { ...dataSource };
    dataSourceCopy.is_shared_with_domain = isSharedWithDomainVar;
    dataSourceCopy.shared_users_email = sharedUsersVar;

    try {
      await api.updateDataSource({
        data_source_id: dataSource.data_source_id,
        create_data_source_request: {
          data_source: dataSourceCopy,
        },
      });

      if (isSharedWithDomainVar || sharedUsersVar) {
        message.success("Connection shared");
      } else {
        message.success("Connection unshared");
      }
      queryClient.invalidateQueries([QueryCacheKey.DATA_SOURCE, dataSource.data_source_id]);
    } catch (error) {
      message.error("Error in updating connection sharing");
    }
  };

  const validateDomainEmail = (email: string) => {
    setErrorMessage("");
    if (!isValidEmail(email)) {
      return "Please provide a valid email.";
    }

    if (!domains.includes(email.split("@")[1])) {
      return "Email ID doesn't belong to the same domain family.";
    }

    return;
  };

  const handleEmailChange = (tagList: string[]) => {
    setSharedUsers(tagList);

    const hasError = tagList.some(tag => validateDomainEmail(tag));
    setHasError(hasError);
  };
  const resetUnshareConfirmation = () => {
    setUnshareConfirmed(false);
    setUnshareConfirmHighlight(false);
  };

  const resetUnshareConnection = () => {
    setIsSharedWithDomain(false);
    setIsSharedWithUser(false);
    setShowShareRadioButtons(false);
    setSharedUsers(undefined);
    updateConnectionSharing(false, undefined);
    if (onChange) {
      onChange(false);
    }
  };

  const readOnly = !onChange;

  const numSharedDataImportsForSource: number =
    dataSource?.data_source_stats?.num_shared_data_imports ?? 0;

  const onShareToggle = (value: boolean) => {
    if (onChange) {
      onChange(value);
      setIsSharedWithDomain(value);
      updateConnectionSharing(value, undefined);
    }
  };

  const generateDomainTooltip = () => {
    return (
      <StyledDomainDiv>
        <Bold>Domains:</Bold>
        {domains.map(domain => (
          <li>{domain}</li>
        ))}
      </StyledDomainDiv>
    );
  };

  const updateFilteredDomainUsersList = (query: string) => {
    setSearchValue(query);
    if (isLoading || !domainUsersList) {
      return;
    }
    setFilteredDomainUsersList([]);
    const filteredDomainUsers: { value: string }[] = [];
    domainUsersList.domain_family_users_email?.forEach(userEmail => {
      const isMatchingQuery = stringSearch(userEmail, query);

      if (isMatchingQuery) {
        filteredDomainUsers.push({ value: userEmail });
      }
    });
    setFilteredDomainUsersList(filteredDomainUsers);
  };

  const DomainFamily = () => {
    if (domains.length === 1) {
      return (
        <div>
          <Highlight>{domains[0]}</Highlight>
        </div>
      );
    } else {
      return (
        <div>
          <Highlight>one of your domains</Highlight>
          <Tooltip
            title={
              <div>
                <Bold>Domains</Bold>
                <BulletList2>
                  {domains.map(domain => (
                    <li key="1">{domain}</li>
                  ))}
                </BulletList2>
              </div>
            }
          >
            <span style={{ paddingLeft: "10px" }}>
              <InfoCircleFilled style={{ color: COLORS.black25 }} />
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  return (
    <Card className="coeblue" noMargin>
      <StyledPanel>
        <FlexBox>
          <SharedUser className="styledSvg" />
          <span style={{ fontWeight: "bold" }}>
            {readOnly ? "Shared connection" : "Share connection with your team"}
          </span>
          {onChange && (
            <Switch
              className="styledToogle"
              size="small"
              onChange={value => (value ? onShareToggle(value) : setShowWarning(true))}
              checked={isSharedWithDomain || isSharedWithUser}
            />
          )}
        </FlexBox>
        <CardPanel>
          {readOnly ? (
            dataSource &&
            dataSource.user && (
              <div style={{ marginLeft: "6px" }}>
                This connection is shared by {getUserEmail(dataSource.user)}. Please contact them
                directly for any changes.
              </div>
            )
          ) : (
            <>
              {isSharedWithDomain && !showShareRadioButtons && (
                <FlexRowDiv>
                  <StyledDiv>
                    Everyone in{" "}
                    <Tooltip title={generateDomainTooltip} placement={"bottomLeft"}>
                      <span className="underline">{`my domain${pluralize(domains.length)}`}</span>
                    </Tooltip>
                  </StyledDiv>
                  {clientFlags?.user_level_connection_sharing_enabled && (
                    <button
                      onClick={() => {
                        setShowShareRadioButtons(true);
                        onEdit ? onEdit(true) : null;
                      }}
                    >
                      edit
                    </button>
                  )}
                </FlexRowDiv>
              )}

              {isSharedWithUser && !showShareRadioButtons && (
                <FlexRowDiv>
                  <StyledDiv onClick={() => setShowUserListModal(true)}>
                    {sharedUsers ? sharedUsers.length : 0} users in {""}
                    <Tooltip title={generateDomainTooltip} placement={"bottomLeft"}>
                      <span className="underline">{`my domain${pluralize(domains.length)}`}</span>
                    </Tooltip>
                  </StyledDiv>
                  {clientFlags?.user_level_connection_sharing_enabled && (
                    <button
                      onClick={() => {
                        setShowShareRadioButtons(true);
                        onEdit ? onEdit(true) : null;
                      }}
                    >
                      edit
                    </button>
                  )}
                </FlexRowDiv>
              )}

              <button
                style={{ marginLeft: "4px", marginBottom: "6px" }}
                onClick={() => setShowLearnMore(true)}
              >
                Learn more
              </button>

              {showShareRadioButtons && (
                <>
                  <StyledRadioWrapper>
                    <Radio
                      className={isSharedWithDomain ? "selectionTitle" : ""}
                      checked={isSharedWithDomain}
                      onClick={() => {
                        setIsSharedWithDomain(true);
                        setIsSharedWithUser(false);
                        setSharedUsers(undefined);
                        setHasError(false);
                      }}
                    >
                      Everyone in{" "}
                      <Tooltip title={generateDomainTooltip} placement={"bottomLeft"}>
                        <span className="underline">{`my domain${pluralize(domains.length)}`}</span>
                      </Tooltip>
                    </Radio>
                    <Radio
                      className={isSharedWithUser ? "selectionTitle" : ""}
                      checked={isSharedWithUser}
                      onClick={() => {
                        setIsSharedWithUser(true);
                        setIsSharedWithDomain(false);
                      }}
                    >
                      Specific users in {""}
                      <Tooltip title={generateDomainTooltip} placement={"bottomLeft"}>
                        <span className="underline">{`my domain${pluralize(domains.length)}`}</span>
                      </Tooltip>
                    </Radio>
                  </StyledRadioWrapper>

                  {isSharedWithUser && (
                    <div>
                      <StyledAutoCompleteTagsInput
                        placeholder="Enter emails"
                        onChange={tags => handleEmailChange(tags)}
                        validator={validateDomainEmail}
                        tags={sharedUsers}
                        onSearch={val => updateFilteredDomainUsersList(val)}
                        options={filteredDomainUsersList}
                      />
                      {hasError && errorMessage && <ErrorDiv>{errorMessage}</ErrorDiv>}
                    </div>
                  )}
                </>
              )}

              <ConfirmBox
                title="Unshare Connection"
                okText="Cancel"
                cancelText="Proceed"
                onOk={() => {
                  resetUnshareConfirmation();
                  setShowWarning(false);
                }}
                onCancel={() => {
                  if (numSharedDataImportsForSource > 0 && !unshareConfirmed) {
                    setUnshareConfirmHighlight(true);
                  } else {
                    setShowWarning(false);
                    resetUnshareConfirmation();
                    resetUnshareConnection();
                  }
                }}
                visible={showWarning}
              >
                Are you sure? Other team members will not be able to use this connection after it's
                unshared.
                {numSharedDataImportsForSource > 0 && (
                  <>
                    <WarnSection>
                      <WarnPanel>
                        <div className="header">
                          <div className="sectionIcon">
                            <InfoCircleFilled />
                          </div>
                          <div className="title">Warning!</div>
                        </div>
                        <div className="body">
                          {`import${pluralize(numSharedDataImportsForSource)} will stop
                          refreshing if this connection is unshared.`}
                        </div>
                      </WarnPanel>
                    </WarnSection>
                    <DeleteCheckbox
                      className={unshareConfirmHighlight ? "error" : ""}
                      checked={unshareConfirmed}
                      onChange={e => {
                        setUnshareConfirmHighlight(false);
                        setUnshareConfirmed(e.target.checked);
                        resetUnshareConnection();
                      }}
                    >
                      Got it, please proceed
                    </DeleteCheckbox>
                  </>
                )}
              </ConfirmBox>

              {showShareRadioButtons && (
                <ButtonSection noBoxShadow={true}>
                  <Button
                    disabled={hasError}
                    target="_blank"
                    type="primary"
                    onClick={() => updateConnectionSharing(isSharedWithDomain, sharedUsers)}
                  >
                    Save
                  </Button>
                </ButtonSection>
              )}

              <ConfirmBox
                closable={true}
                visible={showLearnMore}
                showSubmit={true}
                onSubmit={() => setShowLearnMore(false)}
                showCancel={false}
                onCancel={() => setShowLearnMore(false)}
                submitText="Got it"
              >
                <LearnMoreModal>
                  <div className="center">
                    <img src={`${AssetsHost}/share.svg`} />
                    <div className="title">Sharing Connections</div>
                  </div>

                  <BulletList>
                    <li>
                      <BulletCheck />
                      <div>
                        A team member is anyone using Coefficient with an email from{" "}
                        <DomainFamily />
                      </div>
                    </li>

                    <li>
                      <BulletCheck />
                      <div>
                        Sharing allows team members to import data from the shared connection
                        without having to enter the login credentials themselves.
                      </div>
                    </li>

                    <li>
                      <BulletCheck />
                      <div>Don't worry, your password can never be seen by them.</div>
                    </li>
                  </BulletList>
                </LearnMoreModal>
              </ConfirmBox>

              <ConfirmBox
                closable={true}
                visible={showUserListModal}
                onOk={() => {
                  setShowShareRadioButtons(true);
                  setShowUserListModal(false);
                  onEdit ? onEdit(true) : null;
                }}
                onCancel={() => setShowUserListModal(false)}
                cancelText="Got it"
                okText="Edit"
                showOk={clientFlags?.user_level_connection_sharing_enabled}
              >
                <Typography>
                  {" "}
                  <h4> Users this connection is shared with </h4>{" "}
                </Typography>
                <OverflowYScroll>
                  {sharedUsers && sharedUsers.map(user => <li>{user}</li>)}
                </OverflowYScroll>
              </ConfirmBox>
            </>
          )}
        </CardPanel>
      </StyledPanel>
    </Card>
  );
};
