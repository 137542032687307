import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import { Input, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { SortColumn, SortDirection } from "../../../actions";
import {
  AdminTableFooter,
  LoaderWithPerfTimings,
  Table,
  TableDropdown,
  TableFilterTags,
  Typography,
} from "../../../components";
import { AdminAlert as AlertT, User } from "../../../types";

const Wrapper = styled.div`
  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }
  }
`;

const StyledInputSearch = styled(Input)`
  width: 100%;
  margin-bottom: 8px;
  display: block;
`;

const NameCellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 140;
`;

const StyledExpandedRowWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px;
`;

const StyledTag = styled(Tag)`
  marign-right: 0px;
  border-radius: 5px;
`;

const defaultSortOrder: SortDirection = SortDirection.ascend;

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "s",
    m: "%dm",
    mm: "%dm",
    h: "%dh",
    hh: "%dh",
    d: "%dd",
    dd: "%dd",
    M: "%dM",
    MM: "%dM",
    y: "%dy",
    yy: "%dy",
  },
});

type Props = {
  alerts: AlertT[];
  loading: boolean;
  users: User[];
  totalRecords: number;
  getDomainAlerts: (
    domain: string,
    pageSize: number,
    offset: number,
    sortColumn: SortColumn,
    filters?: string
  ) => void;
  domain: string;
  hidePagination?: boolean;
};

export const Alerts: React.FC<Props> = ({
  alerts,
  loading,
  users,
  domain,
  totalRecords,
  getDomainAlerts,
  hidePagination,
}) => {
  const getTagColor = (type: string) => {
    const colors = {
      monitor_change: "orange",
      monitor_condition: "volcano",
      new_rows_appended: "blue",
      new_rows_with_id: "geekblue",
    };
    return colors[type];
  };

  const [page, setPage] = React.useState(0);
  const history = useHistory();

  const defaultPageSize = 100;

  const [offset, setOffset] = React.useState<number>(0);
  const [filters, setFilters] = useState<Array<{ columnKey: string; values: string[] }>>([]);

  const [sortedColumn, setSortedColumn] = useState<SortColumn>({
    columnKey: "created_dt",
    value: SortDirection.ascend,
  });

  const getFilterValues = (dataKey: string) => {
    const filter = filters.find(f => f.columnKey === dataKey);
    return filter ? filter["values"] : [];
  };

  const dropDownIcon = columnKey => {
    if (sortedColumn.columnKey === columnKey) {
      if (sortedColumn.value === "ascend") {
        return <ArrowDownOutlined />;
      } else {
        return <ArrowUpOutlined />;
      }
    } else {
      return <CaretDownOutlined />;
    }
  };

  const getFilterValuesToLabels = (columnKey: string, values: Array<string>): Array<string> => {
    return values;
  };

  const cleanUpFilters = (filters: Array<{ columnKey: string; values: string[] }>) => {
    // remove filters with values having ~empty~ as first value
    return filters.map(f => (f.values[0] === "~empty~" ? { ...f, values: [] } : f));
  };

  const deleteFilter = (dataKey: string) => {
    const params = new URLSearchParams(location.search);

    setFilters(filters.filter(f => f.columnKey !== dataKey));
    history.push({ pathname: location.pathname, search: params.toString() });
  };

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setOffset(page * defaultPageSize);
  };

  useEffect(() => {
    console.log(filters);
    getDomainAlerts(
      domain,
      defaultPageSize,
      offset,
      sortedColumn,
      JSON.stringify(cleanUpFilters(filters))
    );
  }, [page, offset, filters, sortedColumn]);

  const columns = [
    {
      title: <Typography noWrap> Alert </Typography>,
      dataIndex: "alert_name",
      key: "alert_name",
      showSorterTooltip: false,
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <StyledInputSearch
            placeholder="Search"
            onPressEnter={e => {
              const currentFilter = filters.filter(item => item.columnKey !== "alert_name");
              currentFilter.push({
                columnKey: "alert_name",
                values: [(e.target as HTMLInputElement).value],
              });

              setFilters([...currentFilter]);
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: () => <CaretDownOutlined />,
      width: 200,
      render: alertName => (
        <NameCellWrap>
          <Tooltip title={alertName}>
            <Typography lineClamp={2} noWrap fontWeight={600}>
              {alertName}
            </Typography>
          </Tooltip>
        </NameCellWrap>
      ),
    },
    {
      title: <Typography noWrap> Created By </Typography>,
      dataIndex: "created_by",
      key: "created_by",
      width: 128,
      showSorterTooltip: false,
      sorter: (a, b) => {
        return a.created_by?.localeCompare(b.created_by);
      },
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={alerts ?? []}
          dataKey="created_by"
          filters={filters}
          setFilters={setFilters}
          hideFilters={false}
          setSortedColumn={setSortedColumn}
          disableSort={true}
        />
      ),
      onFilter: (value, record) => record["created_by"] === value,
      filteredValue: getFilterValues("created_by"),
      filterIcon: () => dropDownIcon("created_by"),
    },
    {
      title: <Typography noWrap>Type </Typography>,
      dataIndex: "alert_type",
      key: "alert_type",
      showSorterTooltip: false,
      sorter: (a, b) => a.alert_type?.localeCompare(b.alert_type),
      width: 100,
      render: (type: string) => <StyledTag color={getTagColor(type)}>{type}</StyledTag>,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={alerts ?? []}
          dataKey="alert_type"
          filters={filters}
          setFilters={setFilters}
          hideFilters={false}
          setSortedColumn={setSortedColumn}
          disableSort={true}
        />
      ),
      onFilter: (value, record) => record["alert_type"] === value,
      filteredValue: getFilterValues("alert_type"),
      filterIcon: () => dropDownIcon("alert_type"),
    },
    {
      title: <Typography noWrap>File </Typography>,
      dataIndex: "google_sheets_file_name",
      key: "google_sheets_file_name",
      sorter: (a, b) => a.google_sheets_file_name?.localeCompare(b.google_sheets_file_name),
      width: 150,
      showSorterTooltip: false,
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <StyledInputSearch
            placeholder="Search"
            onPressEnter={e => {
              const currentFilter = filters.filter(
                item => item.columnKey !== "google_sheets_file_name"
              );
              currentFilter.push({
                columnKey: "google_sheets_file_name",
                values: [(e.target as HTMLInputElement).value],
              });

              setFilters([...currentFilter]);
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: () => <CaretDownOutlined />,
      render: fileName => (
        <NameCellWrap>
          <Tooltip title={fileName}>
            <Typography noWrap lineClamp={2}>
              {fileName}
            </Typography>
          </Tooltip>
        </NameCellWrap>
      ),
    },
    {
      title: <Typography noWrap> Created </Typography>,
      dataIndex: "created_dt",
      key: "created_dt",
      showSorterTooltip: false,
      sorter: (a, b) => moment.utc(a.created_dt).diff(moment.utc(b.created_dt)),
      width: 100,
      render: d => {
        if (d) {
          const date = moment(d);
          return <Tooltip title={date.format("MMM DD, YYYY hh:mmA")}>{date.fromNow(true)}</Tooltip>;
        } else {
          return "-";
        }
      },
    },
    {
      title: <Typography noWrap>Sends </Typography>,
      dataIndex: "total_sent",
      key: "total_sent",
      showSorterTooltip: false,
      sorter: (a, b) => a.total_sent - b.total_sent,
      width: 90,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={alerts ?? []}
          dataKey="total_sent"
          filters={filters}
          setFilters={setFilters}
          hideFilters={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      filterIcon: () => dropDownIcon("total_sent"),
    },
    {
      title: <Typography noWrap> Last Send </Typography>,
      dataIndex: "last_send_dt",
      key: "last_send_dt",
      showSorterTooltip: false,
      sorter: (a, b) => moment.utc(a.last_send_dt).diff(moment.utc(b.last_send_dt)),
      width: 100,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={alerts ?? []}
          dataKey="last_send_dt"
          filters={filters}
          setFilters={setFilters}
          hideFilters={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      filterIcon: () => dropDownIcon("last_send_dt"),
      render: d => {
        if (d) {
          const date = moment(d);
          return <Tooltip title={date.format("MMM DD, YYYY hh:mmA")}>{date.fromNow(true)}</Tooltip>;
        } else {
          return "-";
        }
      },
    },
  ];

  const alertsTransformed = alerts.map(alert => ({
    key: alert.alert_id,
    ...alert,
  }));

  const expandedRowRender = row => (
    <StyledExpandedRowWrap>
      <StyledTag>
        <strong>Initial Monitored range:</strong> {row.initial_range_ref_a1}
      </StyledTag>
      {row.condition && (
        <StyledTag>
          <strong>Condition:</strong> {row.condition}
        </StyledTag>
      )}
      <StyledTag>
        <strong>Alert id:</strong> {row.alert_id}
      </StyledTag>
      <StyledTag>
        <strong>Created by email:</strong> {row.created_by_email}
      </StyledTag>
      <StyledTag>
        <strong>File id:</strong> {row.google_sheets_file_id}
      </StyledTag>
    </StyledExpandedRowWrap>
  );

  const locale = {
    emptyText: loading ? <LoaderWithPerfTimings name="DomainAlerts.locale" /> : "No Alerts",
  };

  return (
    <Wrapper>
      {!!filters.length && (
        <TableFilterTags
          filters={filters}
          deleteFilter={deleteFilter}
          valuesToLabels={getFilterValuesToLabels}
        />
      )}

      <Table
        columns={columns}
        dataSource={alertsTransformed}
        loading={false}
        locale={locale}
        rowKey={r => r.alert_id ?? ""}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
        expandable={{
          expandedRowRender: expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretDownFilled onClick={e => onExpand(record, e)} />
            ) : (
              <CaretRightFilled onClick={e => onExpand(record, e)} />
            ),
        }}
      />
      {!hidePagination && (
        <AdminTableFooter
          page={page}
          pageSize={defaultPageSize}
          onPageChange={page => setPageAndOffset(page)}
          data={alerts.map(x => [x.alert_name ?? "", true])}
          totalRowCount={totalRecords}
        />
      )}
    </Wrapper>
  );
};
