import { Input } from "antd";
import React from "react";
import styled from "styled-components";

import { DEPRECATED_Button } from "./DEPRECATED_Button";

const AddSeatsWrap = styled.div`
  .ant-input {
    text-align: center !important;
    border-width: 2px;
    border-right-width: 0px !important;
    border-left-width: 1px;
    height: 32px;
    width: 50px;
    font-weight: bold;
  }

  .ant-btn {
    border-width: 2px;
    border-radius: 0px;
    border-right-width: 2px !important;
    font-weight: bold;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

interface AddSeatsProps {
  onAddUser: () => void;
  onRemoveUser: () => void;
  userCount: number;
  style?: React.CSSProperties;
}

export const AddSeats = ({ onAddUser, onRemoveUser, userCount, style }: AddSeatsProps) => {
  return (
    <AddSeatsWrap style={style}>
      <Input.Group compact>
        <DEPRECATED_Button onClick={onRemoveUser}>-</DEPRECATED_Button>
        <Input style={{ width: "50px" }} value={userCount} />
        <DEPRECATED_Button onClick={onAddUser}>+</DEPRECATED_Button>
      </Input.Group>
    </AddSeatsWrap>
  );
};
