import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { SortColumn, SortDirection } from "../../../actions";
import { useAdminDomainUsers } from "../../../api/users";
import {
  AdminTableFooter,
  LoaderWithPerfTimings,
  Table,
  TableDropdown,
  TableFilterTags,
} from "../../../components";
import { KEY_LABEL } from "../../../constants";
import { EAContext } from "../../../types";
import { ExtAdminContext } from "../ExtAdmin";

const Wrapper = styled.div`
  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }

    font-size: 13px;
  }
`;

export const DomainUsers = () => {
  const { domain } = useContext(ExtAdminContext) as EAContext;
  const [sortedColumn, setSortedColumn] = useState<SortColumn>({
    columnKey: "name",
    value: SortDirection.ascend,
  });

  const [filters, setFilters] = useState<Array<{ columnKey: string; values: string[] }>>([]);

  const [page, setPage] = React.useState(0);

  const [domainUsersPageOffset, setDomainUsersPageOffset] = React.useState(0);

  const domainUsersPageSize = 100;

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setDomainUsersPageOffset(page * domainUsersPageSize);
  };

  const { data: dataDomainUsers, isLoading } = useAdminDomainUsers({
    domain,
    pageSize: domainUsersPageSize,
    offset: domainUsersPageOffset,
    sortColumn: sortedColumn,
    filters: undefined,
  });

  const domainUsers = dataDomainUsers?.domainUsers;

  const getFilterValues = (key: string) => {
    const filter = filters.find(f => f.columnKey === key);
    return filter ? filter["values"] : [];
  };

  const deleteFilter = (key: string) => setFilters(filters.filter(f => f.columnKey !== key));

  const dropDownIcon = columnKey => {
    if (sortedColumn.columnKey === columnKey) {
      if (sortedColumn.value === "ascend") {
        return <ArrowDownOutlined />;
      } else {
        return <ArrowUpOutlined />;
      }
    } else {
      return <CaretDownOutlined />;
    }
  };

  const columns = [
    {
      title: KEY_LABEL["name"],
      dataIndex: "name",
      key: "name",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={domainUsers ?? []}
          dataKey="name"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),

      onFilter: (value, record) => record["name"] === value,
      filteredValue: getFilterValues("name"),
      filterIcon: () => dropDownIcon("name"),
    },
    {
      title: KEY_LABEL["email"],
      dataIndex: "email",
      key: "email",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={domainUsers ?? []}
          dataKey="email"
          filters={filters}
          setFilters={setFilters}
          hideFilters={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["email"] === value,
      filteredValue: getFilterValues("email"),
      filterIcon: () => dropDownIcon("email"),
    },
    {
      title: KEY_LABEL["created_dt"],
      dataIndex: "created_dt",
      key: "created_dt",
      showSorterTooltip: false,
      width: 160,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={domainUsers ?? []}
          dataKey="created_dt"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["created_dt"] === value,
      filteredValue: getFilterValues("created_dt"),
      filterIcon: () => dropDownIcon("created_dt"),
      render: d => {
        if (d) {
          const date = moment(d);
          return date.format("MM/DD/YY hh:mmA");
        } else {
          return "-";
        }
      },
    },
    {
      title: KEY_LABEL["num_data_imports"],
      dataIndex: "num_data_imports",
      key: "num_data_imports",
      showSorterTooltip: false,
      width: 300,
    },
  ];

  const locale = {
    emptyText: isLoading ? <LoaderWithPerfTimings name="DomainUsers.locale" /> : "No Users",
  };

  return (
    <Wrapper>
      {!!filters.length && <TableFilterTags filters={filters} deleteFilter={deleteFilter} />}
      <Table
        columns={columns}
        dataSource={domainUsers ? domainUsers.map(d => ({ ...d, key: d.email })) : []}
        loading={false}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
      />
      <AdminTableFooter
        page={page}
        pageSize={domainUsersPageSize}
        onPageChange={page => setPageAndOffset(page)}
        data={(domainUsers ?? []).map(x => [x.email, true])}
        totalRowCount={dataDomainUsers?.totalRecords ?? 0}
      />
    </Wrapper>
  );
};
