import { UserOutlined } from "@ant-design/icons";
import { SubscriptionUser, UserSubscriptionInfo } from "@coeff/api";
import { Dropdown, Menu, Progress, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  AddSeats,
  Breadcrumb,
  BreadcrumbItem,
  ConfirmBox,
  UserList,
  Typography,
  FlexColumn,
  FlexRow,
  Button,
  EllipsisMenu,
} from "../../../components";
import { TagsInput } from "../../../components/TagsInput";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import {
  getIsGmailLikeDomainFromEmail,
  getPricingSummary,
  planNameFromType,
  pluralize,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection, PriceSummaryProrated } from "../components";
import { AssignUserToLicenseModal } from "../components/AssignUserToLicenseModal";
import { InviteUserToPlanModal } from "../components/InviteUserToPlanModal";

const Container = styled.div`
  color: ${COLORS.black85};
`;

const ScrollList = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const TeamInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  .ant-progress-inner {
    background: ${COLORS.black15};
  }
`;

const HelpContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 64px;
`;

const HelpSubscription = styled.div`
  font-weight: bold;
  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-underline-thickness: 1px;
    cursor: pointer;
  }
`;

const StyledTagsInput = styled(TagsInput)`
  flex-direction: column;
  padding: 6px 12px;

  input {
    flex: unset;
    width: 100%;
  }
`;

const PricingSummaryWrap = styled.div`
  background: ${COLORS.coeblue};
  padding: 8px 24px;
  border-radius: 8px;
  margin: auto;
  font-size: 14px;

  h3 {
    font-weight: bold;
    margin: 4px 0px 12px;
  }
`;

export const ManageTeamV2 = () => {
  const track = useTrack();

  const {
    basePath,
    getUserSubscription,
    enableSubscriptionUpdate,
    userSubscription,
    updateSubscription,
    billingMetadata,
    additionalSeats,
    currentUserPlanManager,
    setAdditionalSeats,
    setContactSupportInfoBox,
    setPlanType,
    currentUserEmail,
    setBillingInterval,
    setDataSources,
    usersInCurrentPlan,
  } = useContext(BillingContext) as BContext;

  useEffect(() => {
    track("billing_manage_team_viewed");
  }, []);

  const isGmailLikeDomain = getIsGmailLikeDomainFromEmail(currentUserEmail);

  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);

  const [downgradeUserConfirm, setDowngradeUserConfirm] = useState<SubscriptionUser | null>(null);

  const [showEditSeatsPanel, setShowEditSeatsPanel] = useState<null | "add" | "remove">(null);

  const [showAddUserConfirm, setShowAddUserConfirm] = useState<boolean>(false);

  const [showAssignUserConfirm, setShowAssignUserConfirm] = useState<boolean>(false);

  const [updateSeatsLoading, setUpdateSeatsLoading] = useState<boolean>(false);

  const subscriptionDetails = userSubscription?.subscription_details;
  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  let sortedUsersInPlan = [...usersInCurrentPlan, ...(userSubscription?.invite_users ?? [])].sort(
    (a, b) => {
      const bName = `${b.user.first_name} ${b.user.last_name}`;
      const aName = `${a.user.first_name} ${a.user.last_name}`;

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    }
  );

  const currentPlanManager = sortedUsersInPlan.find(
    u => u.user.user_id === currentUserPlanManager?.user_id
  );

  if (currentPlanManager) {
    sortedUsersInPlan = sortedUsersInPlan.filter(
      u => u.user.user_id !== currentPlanManager?.user.user_id
    );
    sortedUsersInPlan.unshift(currentPlanManager);
  }

  const downgradeUser = userId => {
    const selectedUsers = usersInCurrentPlan
      .filter(u => u.user?.user_id !== userId)
      .map(u => u.user?.user_id);

    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      selected_user_ids: selectedUsers,
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    track("billing_manage_team_user_removed", {
      removed_user: usersInCurrentPlan.find(u => userId === u.user?.user_id)?.user?.email,
    });
  };

  const removeInvitedUser = email => {
    updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: (userSubscription?.invite_users ?? [])
        .filter(u => u.user?.email !== email)
        .map(u => u.user?.email),
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    track("billing_manage_team_user_removed", {
      removed_user: userSubscription?.invite_users?.find(u => email === u.user?.email)?.user?.email,
    });
  };

  const updateUserSeats = async () => {
    setUpdateSeatsLoading(true);
    const totalUserLicenses =
      showEditSeatsPanel === "add"
        ? (subscriptionDetails?.max_users ?? 0) + additionalSeats
        : showEditSeatsPanel === "remove"
        ? (subscriptionDetails?.max_users ?? 0) - additionalSeats
        : subscriptionDetails?.max_users ?? 0;

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "modify_subscription",
      total_user_licenses: totalUserLicenses,
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });
    setShowEditSeatsPanel(null);
    setShowAddUserConfirm(false);
    setUpdateSeatsLoading(false);
    track(
      showEditSeatsPanel === "add"
        ? "billing_manage_team_licenses_added"
        : "billing_manage_team_licenses_removed",
      {
        selected_num_users: totalUserLicenses,
        [showEditSeatsPanel === "add" ? "num_licenses_added" : "num_licenses_removed"]:
          additionalSeats,
      }
    );
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Your subscription has been updated");
      getUserSubscription();
    }
  };

  useEffect(() => {
    // set the selected billing details to populate the pricing summary
    if (subscriptionDetails?.plan_type) {
      setPlanType(subscriptionDetails?.plan_type);
    }
    if (subscriptionDetails?.billing_interval) {
      setBillingInterval(subscriptionDetails?.billing_interval);
    }
    if (subscriptionDetails?.selected_data_source_types) {
      setDataSources(subscriptionDetails?.selected_data_source_types);
    }
  }, [usersInCurrentPlan]);

  const openAddUserSeatsPopUp = () => {
    setAdditionalSeats(1);
    setShowEditSeatsPanel("add");
  };

  const openRemoveUserSeatsPopUp = () => {
    if (subscriptionDetails?.max_users) {
      setAdditionalSeats(1);
    }
    setShowEditSeatsPanel("remove");
  };

  const subscribedAdditionalUserSeats =
    (subscriptionDetails?.max_users ?? 0) -
    (userSubscription?.invite_users ?? []).length -
    usersInCurrentPlan.length;

  // for computing new pricing summary
  const planMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const planAnnualMonthlyPrice: number =
    billingMetadata?.plans.find(
      p => p.plan_type === subscriptionDetails?.plan_type && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd || 0;

  const numberOfUserInPlan: number =
    showEditSeatsPanel === "add"
      ? (subscriptionDetails?.max_users ?? 0) + additionalSeats
      : showEditSeatsPanel === "remove"
      ? (subscriptionDetails?.max_users ?? 0) - additionalSeats
      : subscriptionDetails?.max_users ?? 0;

  const newPriceSummary = getPricingSummary(
    subscriptionDetails?.plan_type,
    subscriptionDetails?.billing_interval ?? "annually", // billing interval
    planMonthlyPrice, // monthly price for the plan (monthly interval)
    planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
    currentPlan?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
    numberOfUserInPlan, // # of users
    subscriptionDetails?.max_data_source_types ?? 0, // # of data souce
    billingMetadata?.coupon_discount_percentage
  );

  const manageLicensesMenu = (
    <Menu>
      <Menu.Item key={"add-licenses"}>
        <a href="javascript:void(0)" onClick={openAddUserSeatsPopUp}>
          Add Licenses
        </a>
      </Menu.Item>
      <Menu.Item key={"remove-licenses"}>
        <a href="javascript:void(0)" onClick={openRemoveUserSeatsPopUp}>
          Remove Licenses
        </a>
      </Menu.Item>
    </Menu>
  );

  const invitedUserIdSet = new Set(userSubscription?.invite_users?.map(u => u.user.user_id!));

  const planManager = usersInCurrentPlan[0]?.managed_by_user;

  const maxUsersCount = subscriptionDetails?.max_users ?? 0;
  const currentUserCount = usersInCurrentPlan.length;

  const seatUsedPercentage = Math.min(100, (currentUserCount / maxUsersCount) * 100);

  const renderUserRole = (user: SubscriptionUser) => {
    let role = "";

    if (user.user_id === planManager?.user_id) {
      role = "Plan Owner";
    }

    if (invitedUserIdSet.has(user.user_id!)) {
      role = "Invited";
    }

    return (
      <Typography color="textSecondary" fontWeight={500}>
        {role}
      </Typography>
    );
  };

  const renderUserMenu = (user: SubscriptionUser) => {
    return (
      <Menu>
        <Menu.Item key={"remove-user"} onClick={() => setDowngradeUserConfirm(user)}>
          Remove user from plan
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={{ pathname: `${basePath}/subscription`, search: window.location.search }}>
            Plans & Billing
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Manage Users</BreadcrumbItem>
      </Breadcrumb>

      <Content>
        <TeamInfoContainer>
          <FlexRow style={{ justifyContent: "space-between", marginBottom: "24px" }}>
            <Typography fontSize="26px" fontWeight={700}>
              Manage Users
            </Typography>
            <FlexRow gap={0}>
              {!isGmailLikeDomain && (
                <>
                  {planType === "pro" && (
                    <Dropdown
                      overlay={enableSubscriptionUpdate ? manageLicensesMenu : <></>}
                      placement="bottomLeft"
                    >
                      <Button
                        style={{ marginRight: "8px" }}
                        onClick={() => {
                          if (!enableSubscriptionUpdate) {
                            setContactSupportInfoBox({ title: "Manage Licenses" });
                          }
                        }}
                      >
                        Manage Licenses
                      </Button>
                    </Dropdown>
                  )}
                  <Button type="primary" onClick={() => setShowInviteUser(true)}>
                    Invite User
                  </Button>
                </>
              )}
            </FlexRow>
          </FlexRow>
          <FlexColumn>
            <FlexRow style={{ justifyContent: "space-between", marginBottom: "8px" }}>
              <Typography fontWeight={700} variant="body1">
                {`${subscriptionDetails?.max_users} ${planNameFromType(
                  subscriptionDetails?.plan_type!
                )} License${pluralize(subscriptionDetails?.max_users)}`}
              </Typography>
              <FlexColumn style={{ width: 164 }} gap={0}>
                <Typography fontWeight={700} color="inherit" align="right">
                  <span style={{ color: COLORS.coeblue4 }}>{`${currentUserCount}`}</span>
                  {` / ${maxUsersCount} user licenses used`}
                </Typography>
                <Progress
                  percent={seatUsedPercentage}
                  showInfo={false}
                  strokeColor={COLORS.coeblue4}
                />
              </FlexColumn>
            </FlexRow>

            {sortedUsersInPlan.map((user, i) => (
              <UserList
                key={i}
                avatarColor={avatarColors[i % avatarColors.length]}
                name={`${user.user?.first_name || user.user.email} ${user.user?.last_name || ""}`}
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type}
                footer={
                  <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                    <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                      {renderUserRole(user.user)}
                      {enableSubscriptionUpdate && !isGmailLikeDomain && (
                        <Dropdown overlay={renderUserMenu(user.user)} placement="bottomLeft">
                          <Button type="text" size="small">
                            <EllipsisMenu />
                          </Button>
                        </Dropdown>
                      )}
                    </FlexRow>
                  </FlexRow>
                }
              />
            ))}

            {subscribedAdditionalUserSeats > 0 && (
              <UserList
                className="assignUsers"
                avatarColor={COLORS.black25}
                avatarIcon={<UserOutlined />}
                name={subscribedAdditionalUserSeats + " Unassigned Licenses"}
                userId={""}
                isSelectable={false}
                toggleUser={() => setShowInviteUser(true)}
                footer={
                  <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                    <Button type="link" onClick={() => setShowAssignUserConfirm(true)}>
                      {`+ Assign user${pluralize(
                        subscribedAdditionalUserSeats
                      )} to license${pluralize(subscribedAdditionalUserSeats)}`}
                    </Button>
                  </FlexRow>
                }
              />
            )}
          </FlexColumn>
        </TeamInfoContainer>

        <HelpContainer>
          <FAQSection />
        </HelpContainer>
      </Content>

      {/* Invite users popup */}
      <InviteUserToPlanModal open={showInviteUser} onClose={() => setShowInviteUser(false)} />

      {/* Confirm Downgrade to free popup */}
      <ConfirmBox
        title="Please confirm!"
        visible={downgradeUserConfirm !== null}
        okText={`Yes, Downgrade to Free`}
        cancelText="Cancel"
        onOk={() => {
          if (downgradeUserConfirm?.user_id) {
            downgradeUser(downgradeUserConfirm?.user_id);
          } else if (downgradeUserConfirm?.email) {
            removeInvitedUser(downgradeUserConfirm?.email);
          }
          setDowngradeUserConfirm(null);
        }}
        onCancel={() => setDowngradeUserConfirm(null)}
        width={440}
      >
        <>
          <p>
            {downgradeUserConfirm?.first_name ?? downgradeUserConfirm?.email}{" "}
            {downgradeUserConfirm?.last_name} will be removed from your {planNameFromType(planType)}{" "}
            plan and downgraded to the Free plan.
          </p>
          <p>
            Scheduled imports and exporting to data sources will no longer work and other users
            won't be able to refresh{" "}
            {downgradeUserConfirm?.first_name ?? downgradeUserConfirm?.email}'s imports
          </p>
        </>
      </ConfirmBox>

      {/* Add/Remove user seats popup */}
      <ConfirmBox
        title={`${showEditSeatsPanel === "add" ? "Add additional" : "Remove"} user licenses`}
        visible={showEditSeatsPanel !== null && !showAddUserConfirm}
        okText={
          showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0 ? "Got it" : "Next"
        }
        onOk={() => {
          if (showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0) {
            setShowEditSeatsPanel(null);
            return;
          }
          if (additionalSeats > 0) {
            setShowAddUserConfirm(true);
          }
        }}
        width={440}
        onCancel={() => setShowEditSeatsPanel(null)}
        maskClosable={true}
      >
        <>
          {showEditSeatsPanel === "add" && (
            <p>You can assign licenses to users once you purchase.</p>
          )}
          {showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats > 0 && (
            <p>
              You have {subscribedAdditionalUserSeats} unassigned license
              {pluralize(subscribedAdditionalUserSeats)} on your plan that you can remove.
            </p>
          )}
          {showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats === 0 && (
            <p>
              You can only remove unassigned licenses from your plan. All licenses are currently
              assigned. Please unassign a license first.
            </p>
          )}
          {((showEditSeatsPanel === "remove" && subscribedAdditionalUserSeats > 0) ||
            showEditSeatsPanel === "add") && (
            <AddSeats
              onAddUser={() =>
                showEditSeatsPanel === "remove"
                  ? setAdditionalSeats(
                      additionalSeats < subscribedAdditionalUserSeats
                        ? additionalSeats + 1
                        : additionalSeats
                    )
                  : setAdditionalSeats(additionalSeats + 1)
              }
              onRemoveUser={() => {
                if (additionalSeats > 1) {
                  setAdditionalSeats(additionalSeats - 1);
                }
              }}
              userCount={additionalSeats}
            />
          )}
          <div style={{ height: "50px" }}></div>
          {showEditSeatsPanel === "remove" && (
            <HelpSubscription>
              <p
                style={{
                  fontSize: "14px",
                  color: COLORS.black65,
                  background: COLORS.yellowBg2,
                  padding: "8px 8px 8px 8px",
                }}
              >
                Removing user licenses does NOT cancel your subscription with Coefficient,{" "}
                <a
                  href="mailto:support@coefficient.io"
                  className="underline"
                  style={{ color: "inherit" }}
                >
                  contact support to cancel.
                </a>
              </p>
              <div style={{ height: "20px" }}></div>
            </HelpSubscription>
          )}
        </>
      </ConfirmBox>

      {/* Confirm Add/Remove user seats popup */}
      <ConfirmBox
        title={`${showEditSeatsPanel === "add" ? "Add additional" : "Remove"} user licenses`}
        visible={showAddUserConfirm}
        okText={`Confirm Change`}
        cancelText={`Cancel`}
        onOk={() => updateUserSeats()}
        onCancel={() => {
          setShowAddUserConfirm(false);
          setShowEditSeatsPanel(null);
        }}
        width={440}
        confirmLoading={updateSeatsLoading}
      >
        <>
          <p>
            This is your plan summary after {showEditSeatsPanel === "add" ? "adding" : "removing"}{" "}
            {additionalSeats} license
            {pluralize(additionalSeats)}.
          </p>
          <PricingSummaryWrap>
            <h3>{planNameFromType(planType)} Plan (after changes)</h3>
            <PriceSummaryProrated
              editType={showEditSeatsPanel === "add" ? "add-seats" : "remove-seats"}
              newPriceSummary={newPriceSummary}
            />
          </PricingSummaryWrap>
        </>
      </ConfirmBox>

      {/* Confirm Add/Remove user seats popup */}
      <AssignUserToLicenseModal
        open={showAssignUserConfirm}
        onClose={() => setShowAssignUserConfirm(false)}
      />
    </Container>
  );
};
