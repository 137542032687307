import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import {
  AdminExtDataSource,
  DataSource,
  DataSourceConnectionStatus,
  DataSourceType,
} from "@coeff/api-admin-ext";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { SortColumn, SortDirection } from "../../../actions";
import { QueryCacheKey, useDataSources, useAdminDomainUsers } from "../../../api";
import {
  AdminTableFooter,
  ImportTypeIcon,
  LoaderWithPerfTimings,
  Table,
  Button,
  Failed,
  Ok,
  TableDropdown,
  TableFilterTags,
  DataSourceItemMenu,
  Typography,
  Tooltip,
} from "../../../components";
import { KEY_LABEL, MULTIPLE_OAUTH_DATASOURCES, OAUTH_DATASOURCES } from "../../../constants";
import { DataSourceTypeDisplayNames } from "../../../utils";
import { ExtAdminContext } from "../ExtAdmin";

import { DomainDataSourceDetails } from "./DomainDataSourceDetails";

const Wrapper = styled.div`
  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }

    font-size: 13px;
  }
`;

const MenuCellWrap = styled.div`
  width: 40px;
  position: relative;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NameCellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 140;
`;

const ImportsCellWrap = styled.div`
  .ant-btn {
    padding-left: 0px !important;
    text-align: left;
    justify-content: flex-start;
    text-decoration: none !important;
    > a {
      text-decoration: none !important;
    }
  }
`;

const ImportSourceCellWrap = styled.div`
  min-width: 140;
  margin-left: -8px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

const cleanUpFilters = (filters: Array<{ columnKey: string; values: string[] }>) => {
  // remove filters with values having ~empty~ as first value
  const cleanFilters = filters.map(f => (f.values[0] === "~empty~" ? { ...f, values: [] } : f));
  return cleanFilters;
};

export const DomainDataSources: React.FC = () => {
  const [sortedColumn, setSortedColumn] = useState<SortColumn>({
    columnKey: "data_source_name",
    value: SortDirection.ascend,
  });

  const adminExt = useContext(ExtAdminContext);

  const domain = adminExt?.domain;

  const queryClient = useQueryClient();

  const [filters, setFilters] = useState<Array<{ columnKey: string; values: string[] }>>([]);

  const [page, setPage] = React.useState(0);

  const [actionDataSourceId, setActiveDataSourceId] = React.useState<undefined | string>(undefined);

  const [domainDataSourcesPageOffset, setDomainDataSourcesPageOffset] = React.useState(0);

  const [editingName, setEditingName] = useState(false);

  const domainDataSourcesPageSize = 100;

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setDomainDataSourcesPageOffset(page * domainDataSourcesPageSize);
  };

  const { data: dataSourcesData, isLoading } = useDataSources({
    domain,
    pageSize: domainDataSourcesPageSize,
    offset: domainDataSourcesPageOffset,
    sortColumn: sortedColumn,
    filters: JSON.stringify(cleanUpFilters(filters) ?? []),
  });

  const [isUserFilterOpenOnce, setIsUserFilterOpenOnce] = useState<boolean>(false);

  const { data: dataDomainUsers, isLoading: isUsersLoading } = useAdminDomainUsers(
    {
      domain,
      pageSize: 1_000,
      offset: 0,
      sortColumn: sortedColumn,
      filters: undefined,
    },
    { enabled: isUserFilterOpenOnce }
  );

  const usersList = dataDomainUsers?.domainUsers.map(item => {
    return {
      created_by: item.email,
    };
  });

  const dataSources = dataSourcesData?.domainDataSources ?? [];

  const uniqueDataSourceTypeOptions = dataSourcesData?.uniqueDataSourceTypes.map(type => ({
    data_source_type: type,
    label: DataSourceTypeDisplayNames[type] as string,
  }));

  const getFilterValues = (key: string) => {
    const filter = filters.find(f => f.columnKey === key);
    return filter ? filter["values"] : [];
  };

  const deleteFilter = (key: string) => setFilters(filters.filter(f => f.columnKey !== key));

  const getReconnectStatusTooltip = (dataSourceType: DataSourceType, createdBy: string) => {
    if (MULTIPLE_OAUTH_DATASOURCES.includes(dataSourceType)) {
      return `This source needs to be reconnected by the owner ${createdBy}`;
    } else if (OAUTH_DATASOURCES.includes(dataSourceType)) {
      return `This source needs to be reconnected by the owner ${createdBy}`;
    } else {
      return "This source needs to be reconnected";
    }
  };

  const dropDownIcon = columnKey => {
    if (sortedColumn.columnKey === columnKey) {
      if (sortedColumn.value === "ascend") {
        return <ArrowUpOutlined />;
      } else {
        return <ArrowDownOutlined />;
      }
    } else {
      return <CaretDownOutlined />;
    }
  };

  const columns: ColumnsType<AdminExtDataSource> = [
    {
      title: <Typography noWrap>{"Name"}</Typography>,
      dataIndex: "data_source_name",
      key: "data_source_name",
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataSources}
          dataKey="data_source_name"
          filters={filters}
          setFilters={setFilters}
          hideFilters={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      showSorterTooltip: false,
      width: 200,
      onFilter: (value, record) => record["data_source_name"] === value,
      filteredValue: getFilterValues("data_source_name"),
      filterIcon: () => dropDownIcon("data_source_name"),
      render: (name: string) => {
        return (
          <NameCellWrap>
            <Tooltip title={name}>
              <Typography noWrap lineClamp={2} title={name}>
                {name}
              </Typography>
            </Tooltip>
          </NameCellWrap>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["data_source_type"]}</Typography>,
      dataIndex: "data_source_type",
      key: "data_source_type",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={uniqueDataSourceTypeOptions ?? []}
          dataKey="data_source_type"
          hideFilters={false}
          filters={filters}
          setFilters={setFilters}
          disableSort={true}
          setSortedColumn={setSortedColumn}
          valueRenderer={(data: string) => {
            return DataSourceTypeDisplayNames[data] ?? data;
          }}
        />
      ),
      onFilter: (value, record) => record["data_source_type"] === value,
      filteredValue: getFilterValues("data_source_type"),
      filterIcon: () => dropDownIcon("data_source_type"),
      render: d => (
        <ImportSourceCellWrap>
          <ImportTypeIcon type={d} size={"default"} />
          <Typography noWrap lineClamp={2} title={DataSourceTypeDisplayNames[d]}>
            {DataSourceTypeDisplayNames[d]}
          </Typography>
        </ImportSourceCellWrap>
      ),
    },
    {
      title: <Typography noWrap>{KEY_LABEL["data_source_sharing"]}</Typography>,
      dataIndex: "data_source_sharing",
      key: "data_source_sharing",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataSources}
          dataKey="data_source_sharing"
          hideFilters={false}
          filters={filters}
          setFilters={setFilters}
          disableSort={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["data_source_sharing"] === value,
      filteredValue: getFilterValues("data_source_sharing"),
      filterIcon: () => dropDownIcon("data_source_sharing"),
      render: (sharing: string) => {
        return (
          <NameCellWrap>
            <Typography noWrap lineClamp={2} title={sharing}>
              {sharing}
            </Typography>
          </NameCellWrap>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["connection_status"]}</Typography>,
      dataIndex: "connection_status",
      key: "connection_status",
      showSorterTooltip: false,
      width: 100,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={[
            { connection_status: "ok" },
            { connection_status: "failed" },
            { connection_status: "" },
          ]}
          dataKey="connection_status"
          filters={filters}
          valueRenderer={val => {
            if (val === "ok") {
              return "OK";
            } else if (val === "failed") {
              return "Reconnect";
            } else {
              return "-";
            }
          }}
          hideFilters={false}
          disableSort={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      filteredValue: getFilterValues("connection_status"),
      filterIcon: () => dropDownIcon("connection_status"),
      render: (d, record) => {
        if (d === "") {
          return <Status>{"-"}</Status>;
        }

        const statusLabel = d === "ok" ? "OK" : "Reconnect";

        const connectionStatusDetails: DataSourceConnectionStatus | undefined =
          record["connection_status_details"];

        let title: string | undefined;

        if (connectionStatusDetails) {
          const lastCheckedDt = moment(connectionStatusDetails.last_checked_dt);

          title = `Last successfully used at ${lastCheckedDt.format("MM/DD/YY hh:mmA")}`;

          if (statusLabel === "Reconnect") {
            title = getReconnectStatusTooltip(
              record["data_source_type"] as DataSourceType,
              record["created_by"]
            );
          }
        }

        return (
          <Tooltip title={title}>
            <Status>
              {statusLabel === "OK" ? (
                <>
                  <Ok className="statusIcon" /> {statusLabel}
                </>
              ) : (
                <>
                  <Failed className="statusIcon" /> {statusLabel}
                </>
              )}
            </Status>
          </Tooltip>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["num_data_imports"]}</Typography>,
      dataIndex: "num_data_imports",
      key: "num_data_imports",
      showSorterTooltip: false,
      width: 150,
      render: (value, record) => {
        return value === 0 ? (
          <Typography>{value}</Typography>
        ) : (
          <ImportsCellWrap>
            <Button
              type="link"
              href={`/dashboard/admin/imports?domain=${domain}&data_source_id=${record.data_source_id}`}
            >
              {value}
            </Button>
          </ImportsCellWrap>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["num_writebacks"]}</Typography>,
      dataIndex: "num_writebacks",
      key: "num_writebacks",
      showSorterTooltip: false,
      width: 150,
    },
    {
      title: <Typography noWrap>{KEY_LABEL["created_by"]}</Typography>,
      dataIndex: "created_by",
      key: "created_by",
      showSorterTooltip: false,
      width: 200,
      onFilterDropdownOpenChange: open => {
        if (!isUserFilterOpenOnce) {
          setIsUserFilterOpenOnce(true);
        }
      },
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={usersList ?? []}
          dataKey="created_by"
          filters={filters}
          setFilters={setFilters}
          disableSort={true}
          hideFilters={false}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["created_by"] === value,
      filteredValue: getFilterValues("created_by"),
      filterIcon: () => dropDownIcon("created_by"),
      render: (email, r) => {
        return (
          <Tooltip title={r.user_name}>
            <Typography noWrap>{email}</Typography>
          </Tooltip>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["created_dt"]}</Typography>,
      dataIndex: "created_dt",
      key: "created_dt",
      showSorterTooltip: false,
      width: 160,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataSources}
          dataKey="created_dt"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      onFilter: (value, record) => record["created_dt"] === value,
      filteredValue: getFilterValues("created_dt"),
      filterIcon: () => dropDownIcon("created_dt"),
      render: d => {
        let value = "";
        let tooltip: undefined | string;
        if (d) {
          const date = moment(d);
          value = date.format("MM/DD/YY");
          tooltip = date.format("MM/DD/YY hh:mmA");
        } else {
          value = "-";
        }

        return (
          <Tooltip title={tooltip}>
            <Typography noWrap>{value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      title: "",
      showSorterTooltip: false,
      width: 48,
      render: (d: DataSource) => {
        return (
          <MenuCellWrap>
            <DataSourceItemMenu
              noBackground
              noPadding
              dataSource={d}
              onDeleteCb={() => {}}
              onRenameCb={() => {
                setActiveDataSourceId(d.data_source_id);
                setEditingName(true);
              }}
            />
          </MenuCellWrap>
        );
      },
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoaderWithPerfTimings name="DomainDataSources.locale" />
    ) : (
      "No Datasource"
    ),
  };

  return (
    <Wrapper>
      <DomainDataSourceDetails
        editingName={editingName}
        setEditingName={setEditingName}
        dataSourceId={actionDataSourceId}
        setDataSourceId={setActiveDataSourceId}
      />
      {!!filters.length && <TableFilterTags filters={filters} deleteFilter={deleteFilter} />}
      <Table
        onRow={(record, _rowIndex) => {
          return {
            onClick: _event => {
              if (record["data_source_id"] !== undefined) {
                queryClient.invalidateQueries([QueryCacheKey.DATA_SOURCE]);
                setActiveDataSourceId(record["data_source_id"]);
              }
            },
          };
        }}
        columns={columns}
        dataSource={
          dataSourcesData
            ? dataSourcesData.domainDataSources.map(d => ({ ...d, key: d.data_source_id }))
            : []
        }
        loading={false}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
      />
      <AdminTableFooter
        page={page}
        pageSize={domainDataSourcesPageSize}
        onPageChange={page => setPageAndOffset(page)}
        data={dataSources.map(x => [x.data_source_name, true])}
        totalRowCount={dataSourcesData?.totalRecords ?? 0}
      />
    </Wrapper>
  );
};
