import { SubscriptionPlanType } from "@coeff/api";
import { Modal } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import {
  Button,
  FlexColumn,
  FlexRow,
  InputSearch,
  Typography,
  UserList,
} from "../../../components";
import { AVATAR_COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { planNameFromType, pluralize } from "../../../utils";
import { BillingContext } from "../Billing";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;

    .ant-modal-header {
      border-bottom: none;
      background: none;

      .ant-modal-title {
        font-size: 22px;
      }
    }

    .ant-modal-body {
      padding: 0px 24px 24px 24px;
    }

    .scroll-list {
      max-height: 50vh;
      overflow-y: auto;
    }
  }
`;

type Props = {
  open: boolean;
  close: () => void;
  showPlanChangeConfirm: (val: boolean | string) => void;
};

export const SeatSelectionCustomizerModal: React.FC<Props> = ({
  open,
  close,
  showPlanChangeConfirm,
}) => {
  const {
    billingMetadata,
    additionalSeats,
    setAdditionalSeats,
    currentUserEmail,
    selectedBillingInterval,
    setUsers,
    selectedPlanType,
    selectedUsers,
  } = useContext(BillingContext) as BContext;

  const planType: SubscriptionPlanType = selectedPlanType || "pro";

  const [query, setQuery] = useState("");

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  let usersInDomain = [...(billingMetadata?.users_from_domain ?? [])];

  usersInDomain = usersInDomain.sort((a, b) => {
    const bName = `${b.user.first_name} ${b.user.last_name}`;
    const aName = `${a.user.first_name} ${a.user.last_name}`;

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  const filteredUsersInDomain = usersInDomain.filter(u => {
    if (query === "") {
      return true;
    }

    return (
      u.user.email.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.first_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.last_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
  });

  const getPerUserDiscount = () => {
    const monthlyPrice = billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd;
    const annualPrice = billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd;
    if (!monthlyPrice || !annualPrice) {
      return 0;
    }
    return monthlyPrice - annualPrice;
  };

  const selectedUserIds = new Set(selectedUsers.map(u => u.user_id!));

  const toggleUser = (userId: string) => {
    const selectedUserIndex = selectedUsers.findIndex(u => u.user_id === userId);

    if (selectedUserIndex >= 0) {
      const users = [...selectedUsers];

      users.splice(selectedUserIndex, 1);

      setAdditionalSeats(Math.max(0, additionalSeats + 1));
      setUsers(users);
    } else {
      // Do not allow more than 1 user for starter plan
      if (selectedUsers.length > 0 && planType === "starter") {
        return showPlanChangeConfirm(userId);
      }

      const selectedUser = billingMetadata?.users_from_domain.find(
        u => u.user?.user_id === userId
      )?.user;

      if (selectedUser) {
        setAdditionalSeats(Math.max(0, additionalSeats - 1));
        setUsers([...selectedUsers, selectedUser]);
      }
    }
  };

  return (
    <StyledModal
      width={800}
      title="Assign user licenses"
      closable={true}
      visible={open}
      onCancel={close}
      footer={null}
    >
      <FlexColumn gap={1}>
        <FlexRow style={{ justifyContent: "space-between", marginBottom: "8px" }}>
          <Typography fontWeight={600} variant="body1">
            {`${filteredUsersInDomain.length} User${pluralize(filteredUsersInDomain.length)} ${
              query === "" ? `in your workspace` : `matching search`
            }`}
          </Typography>
          <InputSearch
            style={{ width: "50%" }}
            value={query}
            onChange={e => setQuery(e.target.value)}
            autoFocus
          />
        </FlexRow>

        <div className="scroll-list">
          {filteredUsersInDomain.map((user, i) => (
            <UserList
              key={i}
              className="white"
              avatarColor={AVATAR_COLORS[i % AVATAR_COLORS.length]}
              name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
              email={user.user?.email || ""}
              userId={user.user?.user_id || ""}
              isSelectable={true}
              plan={user.plan_type}
              selected={selectedUserIds.has(user.user.user_id!)}
              disabled={!!user.plan_type}
              tooltip={
                user.managed_by_user && user.plan_type
                  ? `User is already on ${planNameFromType(user.plan_type)} plan`
                  : undefined
              }
              toggleUser={toggleUser}
              footer={
                <FlexRow style={{ justifyContent: "flex-end" }}>
                  <Typography color="textSecondary" variant="body3">
                    {user.managed_by_user
                      ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                          user.managed_by_user.last_name || ""
                        }`
                      : ""}
                  </Typography>
                </FlexRow>
              }
            />
          ))}
          {filteredUsersInDomain.length === 0 && (
            <Typography color="textSecondary">No Users found...</Typography>
          )}
        </div>
      </FlexColumn>

      <FlexRow style={{ justifyContent: "space-between", marginTop: "8px" }}>
        <Button type="link" onClick={() => close()} noPadding>
          Cancel
        </Button>
        <Button style={{ width: 120 }} type="primary" onClick={() => close()}>
          Done
        </Button>
      </FlexRow>
    </StyledModal>
  );
};
