import { InfoCircleFilled } from "@ant-design/icons";
import React, { useContext } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb, BreadcrumbItem, Tooltip, UserList } from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { BillingContext } from "../Billing";
import { FAQSection } from "../components";

import { DomainFamilyList } from "./PlanSelector";

const Wrap = styled.div`
  color: ${COLORS.black85};

  .mainTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
`;

const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: 20px;

  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .assignUsers {
    .ant-checkbox-wrapper {
      display: none;
    }

    .email {
      color: ${COLORS.coeblue4};
    }
  }
`;

export const Team = () => {
  const { basePath, billingMetadata, domain, domains_in_domain_family } = useContext(
    BillingContext
  ) as BContext;

  const { path } = useRouteMatch();

  const DomainFamily = () => {
    const domains = domains_in_domain_family;
    if (domains.length === 1) {
      return (
        <div className="mainTitle" style={{ marginBottom: "0px" }}>
          Users from {domains[0]}
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="mainTitle" style={{ marginBottom: "0px" }}>
            Users from your Domains
          </div>

          <Tooltip
            variant="light"
            getPopupContainer={triggerNode => triggerNode}
            title={
              <div>
                <Bold>Domains</Bold>
                <DomainFamilyList>
                  {domains.map(domain => (
                    <li>{domain}</li>
                  ))}
                </DomainFamilyList>
              </div>
            }
          >
            <span style={{ paddingLeft: "10px", marginTop: "3px" }}>
              <InfoCircleFilled style={{ color: COLORS.black25 }} />
            </span>
          </Tooltip>
        </div>
      );
    }
  };
  return (
    <Wrap>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={{ pathname: `${basePath}/subscription`, search: window.location.search }}>
            Account
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>Your team</BreadcrumbItem>
      </Breadcrumb>

      <DomainFamily />
      <br />

      <InnerWrap>
        <div style={{ width: "60%" }}>
          <Section>
            {billingMetadata?.users_from_domain.map((user, i) => (
              <UserList
                key={i}
                avatarColor={
                  avatarColors[
                    (i + billingMetadata?.users_from_domain.length) % avatarColors.length
                  ]
                }
                name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                email={user.user?.email || ""}
                userId={user.user?.user_id || ""}
                plan={user.plan_type}
              />
            ))}
          </Section>
        </div>

        <div style={{ width: "35%" }}>
          <FAQSection />
        </div>
      </InnerWrap>
    </Wrap>
  );
};
