import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import { AddonPlatform } from "@coeff/api";
import { DataImport } from "@coeff/api-admin-ext";
import { Input, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import { DataSourceTypeDisplayNames } from "../../../../src/utils";
import { SortColumn, SortDirection } from "../../../actions";
import { useAdminDataImports } from "../../../api";
import {
  AdminTableFooter,
  ExcelSheetsIcon,
  Failed,
  GoogleSheetsIcon,
  ImportTypeIcon,
  LoaderWithPerfTimings,
  NewWindow,
  Ok,
  Table,
  TableDropdown,
  Typography,
  TableFilterTags,
} from "../../../components";
import { COLORS, KEY_LABEL } from "../../../constants";
import { EAContext } from "../../../types";
import { ExtAdminContext } from "../ExtAdmin";

import { DataImportRunsSubTable } from "./DataImportRunsSubTable";

const Wrapper = styled.div`
  .statusIcon {
    position: relative;
    top: 3px;
    margin-right: 3px;
  }

  & .ant-table-container {
    & .ant-table-filter-trigger {
      padding: 0px 20px;
    }

    font-size: 13px;
  }
`;

const NameCellWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 140;
  word-break: break-all;
`;

const ImportSourceCellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
  min-width: 140;
  margin-left: -8px;

  .ant-avatar {
    flex: 0 0 auto;
  }
`;

const FileCellWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
  min-width: 140;
  margin-left: -8px;

  svg {
    flex: 0 0 auto;
    height: 32px;
    width: 32px;
  }
`;

const SourceFilterWrap = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
`;

const SubTableWrap = styled.div`
  display: flex;
  padding: 1rem;
  box-sizing: border-box;

  strong {
    font-weight: bolder !important;
  }

  .additionDetail {
    margin: 1rem 0px;
  }

  & .leftPanel {
    display: inline-flex;
    flex-direction: column;
    flex: 1;
  }

  & .rightPanel {
    display: inline-flex;
    flex-direction: column;
    flex: 2.5;
    margin-left: 15px;

    & table {
      margin-top: 15px;
      text-align: left;
      table-layout: fixed;

      & thead {
        color: ${COLORS.black15};

        & tr {
          & th {
            padding: 0 12px;
            border-bottom: 1px solid ${COLORS.black15};
          }
        }
      }

      & tbody {
        width: 100%;
        max-height: 400px;

        & tr > td {
          height: 50px;
          border-bottom: 1px solid ${COLORS.black15};
        }
      }
    }
  }
`;

const Status = styled.div`
  white-space: nowrap;
`;

const StyledInputSearch = styled(Input)`
  width: 100%;
  margin-bottom: 8px;
  display: block;
`;

export const DataImports = () => {
  const location = useLocation();
  const history = useHistory();

  const { domain } = useContext(ExtAdminContext) as EAContext;

  const [sortedColumn, setSortedColumn] = useState<SortColumn>({
    columnKey: "last_run_dt",
    value: SortDirection.ascend,
  });

  const [filters, setFilters] = useState<Array<{ columnKey: string; values: string[] }>>([]);

  const [page, setPage] = React.useState(0);

  const dataImportPageSize = 100;

  const [dataImportPageOffset, setDataImportPageOffset] = useState<number>(0);

  const setPageAndOffset = (page: number) => {
    setPage(page);

    setDataImportPageOffset(page * dataImportPageSize);
  };

  const cleanUpFilters = (filters: Array<{ columnKey: string; values: string[] }>) => {
    // remove filters with values having ~empty~ as first value
    return filters.map(f => (f.values[0] === "~empty~" ? { ...f, values: [] } : f));
  };

  const { data: dataImportsData, isLoading } = useAdminDataImports({
    domain,
    pageSize: dataImportPageSize,
    offset: dataImportPageOffset,
    sortColumn: sortedColumn,
    filters: JSON.stringify(cleanUpFilters(filters)),
  });

  const dataImports = dataImportsData?.dataImports;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userEmail = queryParams.get("useremail");

    const dataSourceId = queryParams.get("data_source_id");

    if (userEmail) {
      const userRecord = dataImports?.find(d => d.created_by_email === userEmail);
      if (userRecord) {
        setFilters([{ columnKey: "created_by", values: [userRecord.created_by] }]);
      } else {
        setFilters([{ columnKey: "created_by", values: [userEmail] }]);
      }
    }

    if (dataSourceId) {
      setFilters([{ columnKey: "data_source_id", values: [dataSourceId] }]);
    }
  }, []);

  const getFilterValues = (dataKey: string) => {
    const filter = filters.find(f => f.columnKey === dataKey);
    return filter ? filter["values"] : [];
  };

  const deleteFilter = (dataKey: string) => {
    const params = new URLSearchParams(location.search);

    if (dataKey === "data_source_id") {
      params.delete("data_source_id");
    }

    setFilters(filters.filter(f => f.columnKey !== dataKey));
    history.push({ pathname: location.pathname, search: params.toString() });
  };

  const dropDownIcon = columnKey => {
    if (sortedColumn.columnKey === columnKey) {
      if (sortedColumn.value === "ascend") {
        return <ArrowDownOutlined />;
      } else {
        return <ArrowUpOutlined />;
      }
    } else {
      return <CaretDownOutlined />;
    }
  };

  const sourceInfoFilterMap: Map<string, { data_source_id: string; label: string }> = new Map();

  dataImports?.forEach(record => {
    const sourceId = record.source_info?.data_source_id;

    if (sourceId !== undefined) {
      sourceInfoFilterMap.set(sourceId, {
        label:
          record.data_source_name !== ""
            ? record.data_source_name
            : DataSourceTypeDisplayNames[record.data_source_type!],
        ["data_source_id"]: sourceId,
      });
    }
  });
  const sourceInfoFilterOptions = Array.from(sourceInfoFilterMap.values());

  const getFilterValuesToLabels = (columnKey: string, values: Array<string>): Array<string> => {
    if (columnKey !== "data_source_id") {
      return values;
    }

    const valueLabels = values.map(val => {
      const record = dataImports?.find(item => item.data_source_id === val);

      if (!record) {
        return val;
      }

      return record.data_source_name;
    });

    return valueLabels;
  };

  const columns: ColumnsType<DataImport> = [
    {
      title: <Typography noWrap>{KEY_LABEL["data_import_name"]}</Typography>,
      dataIndex: "data_import_name",
      key: "data_import_name",
      showSorterTooltip: false,
      width: 100,
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <StyledInputSearch
            placeholder="Search"
            onPressEnter={e => {
              const currentFilter = filters.filter(item => item.columnKey !== "data_import_name");
              currentFilter.push({
                columnKey: "data_import_name",
                values: [(e.target as HTMLInputElement).value],
              });

              setFilters([...currentFilter]);
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: () => <CaretDownOutlined />,
      render: d => (
        <NameCellWrap>
          <Typography noWrap lineClamp={2}>
            {d}
          </Typography>
        </NameCellWrap>
      ),
    },
    {
      title: <Typography noWrap>{KEY_LABEL["google_sheets_file_name"]}</Typography>,
      dataIndex: "google_sheets_file_name",
      key: "google_sheets_file_name",
      showSorterTooltip: false,
      width: 150,
      filteredValue: getFilterValues("google_sheets_file_name"),
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8 }}>
          <StyledInputSearch
            placeholder="Search"
            onPressEnter={e => {
              const currentFilter = filters.filter(
                item => item.columnKey !== "google_sheets_file_name"
              );
              currentFilter.push({
                columnKey: "google_sheets_file_name",
                values: [(e.target as HTMLInputElement).value],
              });

              setFilters([...currentFilter]);
              confirm();
            }}
          />
        </div>
      ),
      filterIcon: () => <CaretDownOutlined />,
      render: (d, row) => {
        if (row.import_target_platform === AddonPlatform.GoogleSheets) {
          return (
            <FileCellWrap>
              <GoogleSheetsIcon style={{ verticalAlign: "middle" }} />
              <Typography noWrap lineClamp={2} style={{ verticalAlign: "middle" }}>
                {d || "-"}
              </Typography>
            </FileCellWrap>
          );
        } else {
          return (
            <FileCellWrap>
              <ExcelSheetsIcon />
              <Typography noWrap lineClamp={2}>
                {d || "-"}
              </Typography>
            </FileCellWrap>
          );
        }
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["created_by"]}</Typography>,
      dataIndex: "created_by",
      key: "created_by",
      showSorterTooltip: false,
      width: 140,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataImports ?? []}
          hideFilters={false}
          dataKey="created_by"
          filters={filters}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
          disableSort={true}
        />
      ),
      onFilter: (value, record) => record["created_by"] === value,
      filteredValue: getFilterValues("created_by"),
      filterIcon: () => dropDownIcon("created_by"),
    },
    {
      title: <Typography noWrap>{KEY_LABEL["data_source_type"]}</Typography>,
      dataIndex: "data_source_id",
      key: "data_source_id",
      showSorterTooltip: false,
      width: 200,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={sourceInfoFilterOptions}
          dataKey="data_source_id"
          hideFilters={false}
          filters={filters}
          setFilters={setFilters}
          disableSort={true}
          setSortedColumn={setSortedColumn}
          valueRenderer={item => {
            const option = sourceInfoFilterMap.get(item);
            return (
              <SourceFilterWrap>
                <Typography noWrap lineClamp={1}>
                  {option?.label ?? item}
                </Typography>
              </SourceFilterWrap>
            );
          }}
        />
      ),
      onFilter: (value, record) => {
        return record["data_source_id"] === value;
      },
      filteredValue: getFilterValues("data_source_id"),
      filterIcon: () => dropDownIcon("data_source_id"),
      render: (_d, record) => (
        <ImportSourceCellWrap>
          <ImportTypeIcon size={"default"} type={record.data_source_type} />
          <Typography noWrap lineClamp={2}>
            {record.data_source_name !== ""
              ? record.data_source_name
              : DataSourceTypeDisplayNames[record.data_source_type!]}
          </Typography>
        </ImportSourceCellWrap>
      ),
    },
    {
      title: <Typography noWrap>{KEY_LABEL["last_run_dt"]}</Typography>,
      dataIndex: "last_run_dt",
      key: "last_run_dt",
      showSorterTooltip: false,
      width: 160,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={dataImports ?? []}
          dataKey="last_run_dt"
          filters={filters}
          hideFilters={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      filterIcon: () => dropDownIcon("last_run_dt"),
      render: d => {
        if (d) {
          const date = moment(d);
          return date.format("MM/DD/YY hh:mm A");
        } else {
          return "-";
        }
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["last_run_status_type"]}</Typography>,
      dataIndex: "last_run_status_type",
      key: "last_run_status_type",
      showSorterTooltip: false,
      width: 100,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={[{ run_status_type: "OK" }, { run_status_type: "Failed" }]}
          dataKey="run_status_type"
          filters={filters}
          disableSort={true}
          setFilters={setFilters}
          setSortedColumn={setSortedColumn}
        />
      ),
      filteredValue: getFilterValues("last_run_status_type"),
      filterIcon: () => dropDownIcon("last_run_status_type"),
      render: (d, record) => {
        return (
          <Status>
            {d === "OK" ? (
              <>
                <Ok className="statusIcon" /> {d}
              </>
            ) : (
              <Tooltip title={record.error_description}>
                <Failed className="statusIcon" /> {d}
              </Tooltip>
            )}
          </Status>
        );
      },
    },
    {
      title: <Typography noWrap>{KEY_LABEL["is_scheduled"]}</Typography>,
      dataIndex: "is_scheduled",
      key: "is_scheduled",
      showSorterTooltip: false,
      width: 120,
      filterDropdown: ({ confirm }) => (
        <TableDropdown
          confirm={confirm}
          data={[{ is_scheduled: "Yes" }, { is_scheduled: "No" }]}
          dataKey="is_scheduled"
          filters={filters}
          setFilters={setFilters}
          disableSort={true}
          setSortedColumn={setSortedColumn}
        />
      ),
      filteredValue: getFilterValues("is_scheduled"),
      filterIcon: () => dropDownIcon("is_scheduled"),
      render: d => (d ? "Yes" : "No"),
    },
  ];

  const renderExpandedRow = row => (
    <SubTableWrap>
      <div className="leftPanel">
        <span>Additional Import details</span>
        <div className={"additionDetail"}>
          <strong>Source Details:</strong>
          {row.source_info && row.source_info.sql_is_raw_query_mode ? (
            <div>{`Table: ${row.source_info.sql_table_name}`}</div>
          ) : ["csv", "google_sheets"].includes(row.data_source_type) ? (
            <div>
              <a href={row.data_source_url} target="_blank">
                {row.data_source_details} <NewWindow fill={COLORS.black25} />
              </a>
            </div>
          ) : (
            <div>{row.data_source_details}</div>
          )}
        </div>
        {!!row.schedule_description && (
          <div className={"additionDetail"}>
            <strong>Schedule Details:</strong>
            <div>{row.schedule_description}</div>
          </div>
        )}
        <div className={"additionDetail"}>
          <strong>Created Time:</strong>
          <div>{moment(row.created_dt).format("MM/DD/YY hh:mmA")}</div>
        </div>
      </div>
      <div className="rightPanel">
        <span>Recent Runs</span>
        <DataImportRunsSubTable
          dataImportId={row.data_import_id}
          sourceInfo={row.source_info || {}}
        />
      </div>
    </SubTableWrap>
  );

  const locale = {
    emptyText: isLoading ? <LoaderWithPerfTimings name="DataImports.locale" /> : "No Data Imports",
  };

  return (
    <Wrapper>
      {!!filters.length && (
        <TableFilterTags
          filters={filters}
          deleteFilter={deleteFilter}
          valuesToLabels={getFilterValuesToLabels}
        />
      )}

      <Table
        columns={columns}
        rowKey={r => r.data_import_id}
        dataSource={dataImports?.map(d => ({ ...d, key: d.data_import_id })) ?? []}
        loading={false}
        locale={locale}
        pagination={false}
        scroll={{ x: "auto", y: "auto" }}
        expandable={{
          expandedRowRender: renderExpandedRow,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretDownFilled onClick={e => onExpand(record, e)} />
            ) : (
              <CaretRightFilled onClick={e => onExpand(record, e)} />
            ),
        }}
      />

      <AdminTableFooter
        page={page}
        pageSize={dataImportPageSize}
        onPageChange={page => setPageAndOffset(page)}
        data={(dataImports ?? []).map(x => [x.data_import_id, true])}
        totalRowCount={dataImportsData?.totalRecords ?? 0}
      />
    </Wrapper>
  );
};
