import { UserOutlined } from "@ant-design/icons";
import { DataSourceType, FeatureFlag, SubscriptionPlanType } from "@coeff/api";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  AddSeats,
  DEPRECATED_Button,
  ConfirmBox,
  message,
  Option,
  Radio,
  Select as CoeffSelect,
  Tag,
  Tooltip,
  UserList,
  DotsLoader,
} from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { planNameFromType, pluralize, useTrack } from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection, PriceSummary, PriceSummaryProrated, PriceSummaryText } from "../components";

const Wrap = styled.div`
  color: ${COLORS.black85};
`;

const InnerWrap = styled.div`
  display: flex;
`;

const Title = styled.h1`
  font-weight: bold;
  color: ${COLORS.black85};
  font-size: 24px;

  a {
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.coeblue4};
    margin-left: 10px;
  }
`;

const Box = styled.div`
  padding: 10px 24px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 16px;
  background: ${COLORS.black2};

  &.white {
    background: white;
  }

  &.indented {
    padding: 10px 15px 10px 35px;
  }

  .sectionNumber {
    position: absolute;
    top: 12px;
    left: 0px;
  }

  &.coeblue {
    background: ${COLORS.coeblue};
    margin-bottom: 1rem;
  }

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .ant-radio-wrapper {
    color: ${COLORS.black85};
    font-weight: 600;
    border: 2px solid ${COLORS.black15};
    width: 40%;
    padding: 12px;
    border-radius: 5px;

    &.ant-radio-wrapper-checked {
      color: ${COLORS.coeblue4};
      border-color: ${COLORS.coeblue4};
    }

    margin-right: 16px;
  }

  .checkoutBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin: 10px 0px;
  }

  .selectable {
    background: white;
    border-color: white;
  }

  .additionalUsers {
    .ant-avatar {
      font-size: 24px;
    }

    &:hover {
      border-color: ${COLORS.white};
    }
  }
`;

const Select = styled(CoeffSelect)`
  && {
    margin: 0px 0px 20px;
    width: 100%;

    .ant-select-arrow {
      opacity: 1;
    }

    &:not(.ant-select-focused) {
      .ant-select-selector {
        border-color: ${COLORS.black15};
      }
    }
  }
`;

const StickyBox = styled.div`
  margin-right: 10px;
  position: sticky;
  top: 0px;
  z-index: 10;
`;

const dataSourceTooltip = (
  <div style={{ padding: "10px" }}>
    <p>
      A data source is any distinct system (e.g. Salesforce, HubSpot, MySQL...) you connect through
      Coefficient
    </p>
    <p>
      Importing from CSV files or other Google Sheets doesn't count as an additional data source.
    </p>
    <p>
      Connecting to Slack or Email to send notifications doesn't count as an additional data source
      either.
    </p>
  </div>
);

export const PlanCustomizer = () => {
  const track = useTrack();

  const { value: enablePricingPageUpdates } = useGate(FeatureFlag.EnablePricingPageUpdates);

  const {
    additionalSeats,
    billingMetadata,
    basePath,
    currentUserEmail,
    priceSummary,
    selectedPlanType,
    setPlanType,
    selectedBillingInterval,
    selectedDataSources,
    selectedUsers,
    setAdditionalSeats,
    setBillingInterval,
    setDataSources,
    setUsers,
    userSubscription,
    updateSubscription,
    getUserSubscription,
    usersInCurrentPlan,
  } = useContext(BillingContext) as BContext;

  const history = useHistory();

  const planType: SubscriptionPlanType = selectedPlanType || "pro";

  const planName = planNameFromType(planType);

  // If showPlanChangeConfirm is a string, it represents the userId
  // that we want to add to the plan if the user confirms the change,
  // otherwise assume it's being added to additionalSeats
  const [showPlanChangeConfirm, setShowPlanChangeConfirm] = useState<boolean | string>(false);

  const [upgradeLoading, setUpgradeLoading] = useState<boolean>(false);

  const planData = billingMetadata?.plans.find(
    p => p.plan_type === selectedPlanType && p.billing_interval === selectedBillingInterval
  );

  const subscriptionDetails = userSubscription?.subscription_details;

  useEffect(() => {
    if (!selectedPlanType) {
      return history.push({
        pathname: `${basePath}/plans`,
        search: window.location.search,
      });
    }

    // set default user
    const currentUser = billingMetadata?.users_from_domain.find(
      u => u.user?.email === currentUserEmail
    );

    if (currentUser?.user && selectedUsers.length === 0) {
      setUsers([currentUser.user]);
    }

    if (selectedDataSources.length === 0 && !enablePricingPageUpdates) {
      // set default data sources
      const domainDatasources: DataSourceType[] = [];

      billingMetadata?.all_data_source_types.forEach(ds => {
        if (ds.used_by_domain && ds.data_source_type) {
          domainDatasources.push(ds.data_source_type);
        }
      });

      setDataSources(domainDatasources.slice(0, 5));
    }
  }, []);

  let usersInDomain = [...(billingMetadata?.users_from_domain ?? [])];

  usersInDomain = usersInDomain.sort(function (a, b) {
    return (b.num_imports || 0) - (a.num_imports || 0);
  });

  const currentUserIndex = usersInDomain.findIndex(u => u.user?.email === currentUserEmail);

  if (currentUserIndex >= 0) {
    usersInDomain.splice(0, 0, usersInDomain.splice(currentUserIndex, 1)[0]);
  }

  const toggleUser = (userId: string) => {
    const selectedUserIndex = selectedUsers.findIndex(u => u.user_id === userId);

    if (selectedUserIndex >= 0) {
      const users = [...selectedUsers];

      users.splice(selectedUserIndex, 1);

      setUsers(users);
    } else {
      // Do not allow more than 1 user for starter plan
      if (selectedUsers.length > 0 && planType === "starter") {
        return setShowPlanChangeConfirm(userId);
      }

      const selectedUser = billingMetadata?.users_from_domain.find(
        u => u.user?.user_id === userId
      )?.user;

      if (selectedUser) {
        setUsers([...selectedUsers, selectedUser]);
      }
    }
  };

  const getPerUserDiscount = () => {
    const monthlyPrice = billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "monthly"
    )?.per_user_plan?.per_item_monthly_price_usd;
    const annualPrice = billingMetadata?.plans.find(
      p => p.plan_type === selectedPlanType && p.billing_interval === "annually"
    )?.per_user_plan?.per_item_monthly_price_usd;
    if (!monthlyPrice || !annualPrice) {
      return 0;
    }
    return monthlyPrice - annualPrice;
  };

  const onCheckout = () => {
    track("billing_checkout_clicked", {
      selected_num_data_sources: selectedDataSources.length,
      selected_num_users: selectedUsers.length,
      selected_frequency: selectedBillingInterval,
      selected_plan_type: selectedPlanType,
    });

    if (subscriptionDetails) {
      return upgradeToPro();
    }
    if (selectedPlanType === "starter" && selectedUsers.length + additionalSeats > 1) {
      return message.error("Starter plan is for individuals only. Please select only one user");
    }

    return history.push({
      pathname: `${basePath}/payment`,
      search: window.location.search,
    });
  };

  const upgradeToPro = async () => {
    setUpgradeLoading(true);
    const currentPlan = billingMetadata?.plans.find(
      plan =>
        plan.plan_type === "pro" && plan.billing_interval === subscriptionDetails?.billing_interval
    );

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "modify_subscription",
      selected_user_ids: selectedUsers.map(u => u.user_id),
      selected_data_source_types: selectedDataSources,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });

    track("billing_plan_changed", {
      from_plan_type: "pro",
      to_plan_type: "starter",
    });

    history.push({
      pathname: `${basePath}/subscription`,
      search: window.location.search,
    });
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Plan updated to Pro successfully");
      getUserSubscription();
    }
  };

  // Always default to at least 1 seat
  const totalSeats = selectedUsers.length + additionalSeats || 1;

  return (
    <Wrap>
      <InnerWrap>
        <div style={{ width: "60%", maxWidth: "580px" }}>
          <Title>
            {planName} Plan{" "}
            <Link to={{ pathname: `${basePath}/plans`, search: window.location.search }}>
              View plans &amp; features
            </Link>
          </Title>

          <Box className="white">
            <div className="sectionTitle" style={{ marginBottom: "15px" }}>
              Billing Frequency
            </div>
            {(!subscriptionDetails || subscriptionDetails.billing_interval === "monthly") && (
              <Radio
                checked={selectedBillingInterval === "monthly"}
                onClick={() => setBillingInterval("monthly")}
              >
                Monthly
              </Radio>
            )}
            <Radio
              checked={selectedBillingInterval === "annually"}
              onClick={() => setBillingInterval("annually")}
            >
              Annual &nbsp;
              <Tag color={"#DEF1D5"} style={{ color: "#44AD10" }}>
                Save 17%
              </Tag>
            </Radio>
          </Box>

          {!enablePricingPageUpdates ? (
            <Box>
              <div className="sectionTitle">
                {selectedDataSources.length} Data Source{pluralize(selectedDataSources.length)}
              </div>

              <p>
                One{" "}
                <Tooltip title={dataSourceTooltip} placement="rightTop">
                  <span className="underline">data source</span>
                </Tooltip>{" "}
                is included with your plan. <br />
                Additional data sources are $
                {planData?.per_additional_data_source_addon?.per_item_monthly_price_usd} / user /
                month
              </p>

              <Select
                mode="multiple"
                onChange={values => setDataSources(values)}
                size="middle"
                value={selectedDataSources}
                placeholder="Choose data sources..."
              >
                {(billingMetadata?.all_data_source_types || []).map((ds, i) => (
                  <Option key={i} value={ds.data_source_type || ""}>
                    {ds.label}
                  </Option>
                ))}
              </Select>
            </Box>
          ) : undefined}

          <Box>
            <div className="sectionTitle">{`${totalSeats} User${pluralize(totalSeats)}`}</div>

            <p>
              ${planData?.per_user_plan?.per_item_monthly_price_usd} / user / month{" "}
              {selectedBillingInterval === "annually" && (
                <span style={{ color: "#44AD10", fontWeight: "bold" }}>
                  with ${getPerUserDiscount()} annual plan savings
                </span>
              )}
            </p>

            <div>
              <UserList
                avatarColor={COLORS.black25}
                avatarIcon={<UserOutlined />}
                className="additionalUsers"
                name={"Add Additional User Licenses"}
                email={"and assign them later"}
                userId={""}
                isSelectable={true}
                selected={false}
                disabled={true}
                footer={
                  <AddSeats
                    onAddUser={() => {
                      // Do not allow more than 1 user for starter plan
                      if (
                        (additionalSeats >= 1 ||
                          (additionalSeats === 0 && selectedUsers.length > 0)) &&
                        planType === "starter"
                      ) {
                        return setShowPlanChangeConfirm(true);
                      }

                      setAdditionalSeats(additionalSeats + 1);
                    }}
                    onRemoveUser={() =>
                      setAdditionalSeats(additionalSeats === 0 ? 0 : additionalSeats - 1)
                    }
                    userCount={
                      selectedUsers.length === 0 && additionalSeats === 0 ? 1 : additionalSeats
                    }
                  />
                }
              />

              {usersInDomain
                .filter(
                  u =>
                    !u.plan_type ||
                    usersInCurrentPlan.some(up => up.user?.user_id === u.user?.user_id)
                )
                .map((user, i) => (
                  <UserList
                    key={i}
                    avatarColor={avatarColors[i % avatarColors.length]}
                    name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                    email={user.user?.email || ""}
                    userId={user.user?.user_id || ""}
                    isSelectable={true}
                    plan={user.plan_type}
                    selected={selectedUsers.some(u => u.email === user.user?.email)}
                    disabled={
                      !!user.plan_type &&
                      usersInCurrentPlan.every(up => up.user?.user_id !== user.user?.user_id)
                    }
                    toggleUser={toggleUser}
                  />
                ))}
              {usersInDomain
                .filter(
                  u =>
                    u.plan_type &&
                    usersInCurrentPlan.every(up => up.user?.user_id !== u.user?.user_id)
                )
                .map((user, i) => (
                  <UserList
                    key={i}
                    avatarColor={avatarColors[i % avatarColors.length]}
                    name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                    email={user.user?.email || ""}
                    userId={user.user?.user_id || ""}
                    isSelectable={true}
                    plan={user.plan_type}
                    selected={false}
                    disabled={!!user.plan_type}
                    toggleUser={() => {}}
                    footer={
                      <div style={{ color: COLORS.black45 }}>
                        {user.managed_by_user
                          ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                              user.managed_by_user.last_name || ""
                            }`
                          : ""}
                      </div>
                    }
                  />
                ))}
            </div>
          </Box>
        </div>

        <div style={{ width: "35%", maxWidth: "320px", margin: "0px auto" }}>
          <StickyBox>
            <Box className="coeblue" style={{ padding: "20px 30px" }}>
              <div className="sectionTitle">Your {planName} Plan</div>
              {subscriptionDetails ? (
                <PriceSummaryProrated editType="upgrade-to-pro" newPriceSummary={priceSummary} />
              ) : (
                <PriceSummary />
              )}

              <DEPRECATED_Button
                disabled={upgradeLoading}
                className="checkoutBtn"
                type="primary"
                onClick={onCheckout}
              >
                {upgradeLoading ? <DotsLoader color="white" size="large" /> : "Checkout"}
              </DEPRECATED_Button>

              {!subscriptionDetails && <PriceSummaryText />}
            </Box>

            <FAQSection />
          </StickyBox>
        </div>
      </InnerWrap>

      <ConfirmBox
        visible={Boolean(showPlanChangeConfirm)}
        title="Change plan to Pro?"
        cancelText="Cancel"
        okText="Change to Pro"
        onCancel={() => setShowPlanChangeConfirm(false)}
        onOk={() => {
          setPlanType("pro");

          if (typeof showPlanChangeConfirm === "string") {
            const selectedUser = billingMetadata?.users_from_domain.find(
              u => u.user?.user_id === showPlanChangeConfirm
            )?.user;

            if (selectedUser) {
              setUsers([...selectedUsers, selectedUser]);
            }
          } else {
            setAdditionalSeats(additionalSeats + 1);
          }

          setShowPlanChangeConfirm(false);
        }}
        width={400}
      >
        Adding additional users requires a Pro plan. Do you want to change your plan to Pro?
      </ConfirmBox>
    </Wrap>
  );
};
