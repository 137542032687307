import { DefaultApi, SnowflakeAppInfo } from "@coeff/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Ajv from "ajv";
import { Space, message } from "antd";
import React, { useEffect, useState } from "react";
import * as sqlHighlight from "sql-highlight";
import styled from "styled-components";

import { QueryCacheKey } from "../api";
import {
  Alert,
  Button,
  ConfirmDeleteDataSourceAppInfoModal,
  Form,
  Input,
  LoaderWithPerfTimings,
  NewWindow,
  SettingsLayout,
  TextArea,
  Tooltip,
  Typography,
} from "../components";
import { config } from "../config";
import { COLORS } from "../constants";
import { useApiContext, useTrack } from "../utils";

const StyledAlert = styled(Alert)`
  line-height: 1.8em;

  code {
    white-space: nowrap;
    background-color: ${COLORS.black4};
    padding: 4px 6px;
  }
`;

const FormGroup = styled.div`
  label {
    font-weight: bold;
  }
  margin-bottom: 12px;
`;

const IPWhitelistContainer = styled.div`
  margin-top: 24px;
  background-color: ${COLORS.black4};
  padding: 15px 24px;
`;

const SQLCodeBlockContainer = styled.div`
  position: relative;
  background-color: ${COLORS.black4};
  white-space: pre;
  font-family: monospace;
  font-size: 12px;
  padding: 16px 24px;
  max-width: 800px;

  & > button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-weight: normal;
  }

  .sql-hl-bracket {
  }

  .sql-hl-function {
    color: #087959;
  }

  .sql-hl-keyword {
    font-weight: 500;
    color: #085bd7;
  }

  .sql-hl-identifier {
    color: #191e24;
  }

  .sql-hl-number {
    color: #087959;
  }

  .sql-hl-special {
  }

  .sql-hl-string {
    color: #860112;
  }

  .sql-hl-whitespace {
  }
`;

const StyledInstructionsList = styled.ol`
  > li {
    margin-bottom: 16px;
  }
`;

const StyledTextArea = styled(TextArea)`
  font-family: ui-monospace, "Courier New", monospace;
  font-weight: 600;
  width: 800px;
  resize: none;
`;

type SQLCodeBlockProps = {
  sql: string;
};

const SQLCodeBlock: React.FC<SQLCodeBlockProps> = props => {
  const [justClickedCounter, setJustClickedCounter] = useState(0);

  const onCopyClicked = async () => {
    await navigator.clipboard.writeText(props.sql);
    setJustClickedCounter(counter => counter + 1);
    setTimeout(() => {
      setJustClickedCounter(counter => counter - 1);
    }, 4000);
  };

  const justClicked = justClickedCounter > 0;

  const sqlSegments = sqlHighlight.getSegments(props.sql).map(segment =>
    // Snowflake-specific keywords
    ["SECURITY", "INTEGRATION", "INTEGRATIONS", "RETURN"].includes(segment.content)
      ? { name: "keyword", content: segment.content }
      : segment
  );

  const highlightedSQL = sqlSegments.map((segment, i) => (
    <span key={i} className={`sql-hl-${segment.name}`}>
      {segment.content}
    </span>
  ));

  return (
    <SQLCodeBlockContainer>
      {highlightedSQL}
      <Button
        size="small"
        type={justClicked ? "default" : "primary"}
        htmlType="button"
        onClick={() => onCopyClicked()}
      >
        {justClicked ? "Copied" : "Copy"}
      </Button>
    </SQLCodeBlockContainer>
  );
};

const PopoutLink: React.FC<React.PropsWithChildren<{ href: string }>> = ({ children, href }) => {
  return (
    <a target="_blank" href={href}>
      {children} <NewWindow fill={COLORS.black65} style={{ marginRight: 3 }} />
    </a>
  );
};

const fetchSnowflakeAppInfo = async (api: DefaultApi): Promise<SnowflakeAppInfo | null> => {
  const response = await api.listSnowflakeAppInfo();
  if (response.data.apps_list.length) {
    return response.data.apps_list[0];
  }
  return null;
};

const deleteSnowflakeAppInfo = async (api: DefaultApi, snowflakeAppInfo: SnowflakeAppInfo) => {
  await api.deleteSnowflakeAppInfo({
    delete_snowflake_app_info_request: {
      client_id: snowflakeAppInfo.client_id,
      domain: snowflakeAppInfo.domain,
    },
  });
};

const upsertSnowflakeAppInfo = async (api: DefaultApi, validConfigJSON: ValidConfigJSON) => {
  await api.upsertSnowflakeAppInfo({
    upsert_snowflake_app_info_request: {
      snowflake_account_id: validConfigJSON["a"],
      client_id: validConfigJSON["o"]["OAUTH_CLIENT_ID"],
      client_secret: validConfigJSON["o"]["OAUTH_CLIENT_SECRET"],
    },
  });
};

const ajv = new Ajv();
const configJSONValidator = ajv.compile({
  type: "object",
  properties: {
    a: { type: "string" },
    o: {
      type: "object",
      properties: {
        OAUTH_CLIENT_SECRET: { type: "string" },
        OAUTH_CLIENT_ID: { type: "string" },
      },
      required: ["OAUTH_CLIENT_SECRET", "OAUTH_CLIENT_ID"],
      additionalProperties: true,
    },
  },
  required: ["a", "o"],
  additionalProperties: false,
});

type ValidConfigJSON = {
  a: string;
  o: {
    OAUTH_CLIENT_SECRET: string;
    OAUTH_CLIENT_ID: string;
  };
};

const tryParseUserEnteredConfigJSON = (text: string): ValidConfigJSON | null => {
  let parsedJSON: any;
  try {
    parsedJSON = JSON.parse(text);
  } catch (err) {
    // Invalid JSON
    return null;
  }
  const validateResult = configJSONValidator(parsedJSON);
  // Since we are not using $async: true JSON schemas, the result will always be a boolean
  // (and not a promise) at runtime
  if (!validateResult) {
    // JSON does not conform to schema
    return null;
  }
  return parsedJSON;
};

const getCreateOrAlterIntegrationSQL = (): string => {
  // Note: we use a SQL snippet with two statements (`CREATE ... IF NOT EXISTS` followed by
  // `ALTER`), since the simpler, single-statement alternatives have the following drawbacks:
  //   - `CREATE OR REPLACE ...` regenerates OAuth the client ID and secret, invalidating
  //     previously granted Snowflake OAuth tokens
  //   - `CREATE ... IF NOT EXISTS` would not update the existing integration with the latest
  //     recommended settings

  const integrationName = config.SNOWFLAKE_SECURITY_INTEGRATION_NAME;
  const oauthRedirectUrl = `${config.OAUTH_REDIRECT_URL_BASE}/snowflake`;

  // prettier-ignore
  const sql =
`BEGIN
    CREATE SECURITY INTEGRATION IF NOT EXISTS ${integrationName}
      TYPE = oauth
      OAUTH_CLIENT = custom
      OAUTH_CLIENT_TYPE = confidential
      OAUTH_REDIRECT_URI = '${oauthRedirectUrl}';
    ALTER SECURITY INTEGRATION COEFFICIENT_DEV_TOMMY SET
      OAUTH_REDIRECT_URI = '${oauthRedirectUrl}'
      OAUTH_ISSUE_REFRESH_TOKENS = true
      OAUTH_REFRESH_TOKEN_VALIDITY = 7776000
      OAUTH_USE_SECONDARY_ROLES = 'IMPLICIT'
      ENABLED = true;
END;`

  return sql;
};

const getSelectOAuthInfoSQL = (): string => {
  // Returns a Snowflake SQL snippet which retrieves the Snowflake account identifier
  // and OAuth client ID/secret

  const integrationName = config.SNOWFLAKE_SECURITY_INTEGRATION_NAME;

  // prettier-ignore
  const sql =
`SELECT to_json(object_construct(
  'a', lower(concat(current_organization_name(), '-', current_account_name())),
  'o', parse_json(system$show_oauth_client_secrets('${integrationName}'))
)) as "Configuration JSON";`;

  return sql;
};

export const SnowflakeOAuthSettings: React.FC = () => {
  const track = useTrack();

  const [userEnteredConfigJSON, setUserEnteredConfigJSON] = useState("");

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [_, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "Snowflake OAuth Settings - Coefficient Workspace";
    track("workspace_snowflake_oauth_settings_viewed");
  }, []);

  const { apiClient: api } = useApiContext();

  const queryClient = useQueryClient();

  const fetchAppInfoQuery = useQuery(
    [QueryCacheKey.SNOWFLAKE_APP_INFO],
    () => fetchSnowflakeAppInfo(api),
    { cacheTime: 0 }
  );
  const { data: snowflakeAppInfo } = fetchAppInfoQuery;

  const deleteAppInfoMutation = useMutation(
    (appInfo: SnowflakeAppInfo) => deleteSnowflakeAppInfo(api, appInfo),
    {
      onSuccess: () => message.success("Snowflake OAuth configuration deleted"),
      onError: () => message.error("Something went wrong. Please try again."),
      onSettled: () => queryClient.invalidateQueries([QueryCacheKey.SNOWFLAKE_APP_INFO]),
    }
  );

  const upsertAppInfoMutation = useMutation(
    (validConfigJSON: ValidConfigJSON) => upsertSnowflakeAppInfo(api, validConfigJSON),
    {
      onSuccess: () => message.success("Snowflake OAuth configured successfully"),
      onError: () => message.error("Something went wrong. Please try again."),
      onSettled: () => queryClient.invalidateQueries([QueryCacheKey.SNOWFLAKE_APP_INFO]),
    }
  );

  const isLoading = [fetchAppInfoQuery, deleteAppInfoMutation, upsertAppInfoMutation].some(
    x => x.isLoading
  );

  if (isLoading) {
    return (
      <SettingsLayout title="Snowflake OAuth Settings" importTypeIcon="snowflake">
        {contextHolder}
        <LoaderWithPerfTimings name="SnowflakeOAuthSettings" />
      </SettingsLayout>
    );
  }

  if (fetchAppInfoQuery.isError) {
    return (
      <SettingsLayout importTypeIcon="snowflake" title="Snowflake OAuth Settings">
        {contextHolder}
        <StyledAlert
          type="error"
          showIcon
          message="Error loading Snowflake OAuth Settings"
          description="Please try refreshing this page, or contact support@coefficient.io"
        />
      </SettingsLayout>
    );
  }

  if (snowflakeAppInfo) {
    // Snowflake OAuth already configured
    return (
      <SettingsLayout importTypeIcon="snowflake" title="Snowflake OAuth Settings" width="narrow">
        {contextHolder}
        <StyledAlert
          type="info"
          showIcon
          message="Snowflake OAuth has been configured for your domain"
          description="Connect to Snowflake in the Coefficient sidebar in Google Sheets."
        />
        <Form>
          <FormGroup>
            <label>Account</label>
            <Input disabled value={snowflakeAppInfo.account_id} />
          </FormGroup>
          <FormGroup>
            <label>Client ID</label>
            <Input disabled value={snowflakeAppInfo.client_id} />
          </FormGroup>
          <FormGroup>
            <label>Client Secret</label>
            <Input disabled type="password" value="___________" />
          </FormGroup>
          <Button onClick={() => setIsDeleteModalVisible(true)}>Delete</Button>
        </Form>
        <IPWhitelistContainer>
          <p>
            <strong>IP Whitelisting</strong>
          </p>
          <p>
            If your database is behind a firewall or private network, you need to whitelist{" "}
            <strong>all three</strong> of our IP addresses:
          </p>
          <strong>
            34.217.184.131
            <br />
            44.234.233.60
            <br />
            52.32.132.51
          </strong>
        </IPWhitelistContainer>
        <ConfirmDeleteDataSourceAppInfoModal
          open={isDeleteModalVisible}
          isLoading={deleteAppInfoMutation.isLoading}
          dataImportCount={snowflakeAppInfo.data_import_count}
          dataSourceCount={snowflakeAppInfo.data_source_count}
          onOk={() => {
            setIsDeleteModalVisible(false);
            deleteAppInfoMutation.mutate(snowflakeAppInfo);
          }}
          onCancel={() => setIsDeleteModalVisible(false)}
          dataSourceType="snowflake"
        />
      </SettingsLayout>
    );
  }

  const placeholderConfigJSON = JSON.stringify(
    {
      a: "<snowflake_account_id>",
      o: {
        OAUTH_CLIENT_ID: "<oauth_client_id>",
        OAUTH_CLIENT_SECRET: "<oauth_client_secret>",
        OAUTH_CLIENT_SECRET_2: "<oauth_client_secret_2>",
      },
    },
    null,
    2
  );

  const handleConfigJSONEntered = (text: string) => {
    try {
      const parsedJSON = JSON.parse(text);
      text = JSON.stringify(parsedJSON, null, 2);
    } catch (err) {}
    setUserEnteredConfigJSON(text);
  };

  const validConfigJSON = tryParseUserEnteredConfigJSON(userEnteredConfigJSON);

  return (
    <SettingsLayout importTypeIcon="snowflake" title="Snowflake OAuth Settings">
      {contextHolder}
      <p>
        Setting up{" "}
        <PopoutLink href="https://docs.snowflake.com/en/user-guide/oauth-custom">
          Snowflake OAuth
        </PopoutLink>{" "}
        allows your organization's Coefficient users to connect to Snowflake securely using
        Snowflake's built-in OAuth service.
      </p>
      <StyledAlert
        type="info"
        showIcon
        message={
          <span>
            To configure Snowflake OAuth, you must be a Snowflake <code>ACCOUNTADMIN</code> or have
            a custom role with <code>CREATE INTEGRATION</code> privileges.
          </span>
        }
      />
      <div>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Instructions
        </Typography>
        <StyledInstructionsList>
          <li>
            <p>
              Log in to <PopoutLink href="https://app.snowflake.com/">Snowflake</PopoutLink> and
              create a new SQL Worksheet.
            </p>
          </li>
          <li>
            <p>
              Select the <code>ACCOUNTADMIN</code> role or, alternatively, a custom role with the{" "}
              <PopoutLink href="https://docs.snowflake.com/en/sql-reference/sql/create-security-integration-oauth-snowflake#access-control-requirements">
                appropriate privileges
              </PopoutLink>
              .
            </p>
          </li>
          <li>
            <p>Run this SQL to register Coefficient as a Snowflake OAuth app:</p>
            <SQLCodeBlock sql={getCreateOrAlterIntegrationSQL()} />
          </li>
          <li>
            <p>
              Run the following SQL command to retrieve your Snowflake OAuth configuration
              information:
            </p>
            <SQLCodeBlock sql={getSelectOAuthInfoSQL()} />
          </li>
          <li>
            <p>Copy the SQL command output from the previous step and paste it below:</p>
            <Tooltip
              placement="right"
              open={!!userEnteredConfigJSON && !validConfigJSON}
              title="Invalid configuration JSON"
            >
              <StyledTextArea
                status={!!userEnteredConfigJSON && !validConfigJSON ? "error" : undefined}
                maxLength={1000}
                rows={8}
                spellCheck={false}
                placeholder={placeholderConfigJSON}
                value={userEnteredConfigJSON}
                onChange={e => handleConfigJSONEntered(e.target.value)}
                onPaste={e => {
                  e.preventDefault();
                  handleConfigJSONEntered(e.clipboardData.getData("text"));
                }}
              />
            </Tooltip>
          </li>
          <Space />
          <Button
            type="primary"
            style={{ width: 200 }}
            disabled={!validConfigJSON}
            onClick={() => validConfigJSON && upsertAppInfoMutation.mutate(validConfigJSON)}
          >
            Save
          </Button>
        </StyledInstructionsList>
      </div>
    </SettingsLayout>
  );
};
