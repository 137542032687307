import { CloseCircleFilled, CommentOutlined, InfoCircleFilled } from "@ant-design/icons";
import { FeatureFlag, SubscriptionPlanType } from "@coeff/api";
import { Progress } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useGate } from "statsig-react";
import styled from "styled-components";

import {
  Button,
  Card,
  ConfirmBox,
  DEPRECATED_Button,
  Excel,
  GiftIcon,
  message,
  NewWindow3,
  PaperContent,
  PlanTag,
  Switch,
  SwitchLabel,
  Tag,
  Tick,
  Tooltip,
  Typography,
} from "../../../components";
import { COLORS } from "../../../constants";
import { BContext, PricingSummary } from "../../../types";
import {
  getFreeTrialNumFractionalDaysLeft,
  getPricingSummary,
  pluralize,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection, PriceSummaryProrated } from "../components";

const FIRST_COLUMN_WIDTH = 210;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: calc(100vw - 670px);
  min-width: 805px;
  margin-bottom: 16px;
  gap: 10px;
`;

const Banner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  padding: 4px 8px;
  background-color: ${COLORS.black4};
  border-radius: 6px;
`;

const BannerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BannerAction = styled.a`
  color: ${COLORS.black85};
  font-weight: 600;
  margin-right: 4px;

  &:hover {
    color: ${COLORS.black85};
  }
`;

const Title = styled.div`
  h2 {
    font-weight: bold;
    color: ${COLORS.black85};
    font-size: 24px;
    width: ${FIRST_COLUMN_WIDTH}px;
    margin: 0;
  }

  .desc {
    color: ${COLORS.black45};
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const SelectorWrap = styled.div`
  display: flex;
  color: ${COLORS.black85};
  align-items: flex-end;

  .column {
    &.labels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 700px;
      width: ${FIRST_COLUMN_WIDTH}px;
    }

    &.plan {
      text-align: center;
      width: 230px;

      .planHeader {
        text-align: center;
        margin-left: 10px;
        padding: 8px 16px;
        font-weight: bold;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .planBox {
        margin-left: 10px;
        padding: 20px;
        border-radius: 16px;

        &.noTopCurve {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }

      &.enterprise {
        .planBox {
          background: ${COLORS.greenBg};
          color: ${COLORS.black85};
        }
      }

      &.starter {
        .planHeader {
          background: #d5ecff;
          color: ${COLORS.black85};
        }
        .planBox {
          background: ${COLORS.coeblue};
          color: ${COLORS.black85};
        }
      }

      &.pro {
        .planHeader {
          background: ${COLORS.yellowBg};
          color: ${COLORS.black85};
        }
        .planBox {
          background: ${COLORS.yellowBg2};
          color: ${COLORS.black85};
        }
      }

      .mostPopular {
        font-size: 15px;
        font-weight: bold;
        height: 50px;

        hr {
          border: 3px solid #ffd278;
          width: 120px;
          margin: -10px auto 0px;
        }
      }

      .planDetails {
        text-align: left;
      }

      .planTitle,
      .planPrice {
        font-size: 24px;
        font-weight: bold;

        &.planPrice {
          margin: 10px 0px 0px;
          font-size: 32px;

          &.volDiscount {
            font-size: 16px;
            margin: 22px 0px 0px;
          }
        }
      }

      .ctaBtn {
        margin: 16px 0px 16px;
        padding: 0px 25px;
        font-weight: bold;
        height: 40px;
        width: 100%;

        &.selected {
          pointer-events: none;
          box-shadow: none;
          background: white;
          border-color: white;
          color: ${COLORS.coeblue4};
        }
      }
    }

    &.otherUsers {
      flex: 1;
      height: 582px;
      margin-left: 30px;

      h3 {
        font-weight: bold;
        font-size: 18px;
      }

      a {
        font-weight: bold;
      }

      .userList {
        margin: 0px auto 40px;

        a {
          display: block;
          margin-top: 5px;
          color: ${COLORS.coeblue4};
        }
      }
    }

    .row {
      font-weight: bold;
      margin: 16px 0px;
    }

    .underline {
      border-bottom: 1px dotted ${COLORS.black45};
      cursor: pointer;
    }
  }
`;

const SelectorWrapV2 = styled(SelectorWrap)<{ shouldShowGmailDiscount?: boolean }>`
  .column {
    &.labels {
      min-height: ${({ shouldShowGmailDiscount }) =>
        shouldShowGmailDiscount ? "1482px" : "1460px"};
    }

    .row {
      font-weight: 500;
    }

    .row.header {
      font-weight: 700;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 24px;
    }

    .planBox .planDetails .planPrice {
      margin: 0;
    }
  }

  .column.plan {
    min-width: 200px;
  }

  .column.otherUsers {
    height: 1410px;
    padding-top: 8px;
  }
`;

const FreePlanBox = styled.div`
  background: ${COLORS.black4};
  margin: 16px 0px 24px 220px;
  padding: 16px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  color: ${COLORS.black65};
  align-items: center;
  width: 680px;
  display: flex;
  gap: 24px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    flex: 1 0 auto;
  }

  div {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.25;
  }
`;

const ConfirmBoxList = styled.ul`
  list-style: none;
  padding-left: 0px;

  li {
    margin: 8px;

    .anticon {
      color: ${COLORS.red};
      margin-right: 5px;
    }
  }
`;

const PricingSummaryWrap = styled.div`
  background: ${COLORS.coeblue};
  padding: 8px 24px;
  border-radius: 8px;
  width: 320px;
  margin: auto;
  font-size: 14px;
  margin-bottom: 16px;

  h3 {
    font-weight: bold;
    margin: 4px 0px 12px;
  }
`;

export const DomainFamilyList = styled.ul`
  margin: 7px 0px 0px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    font-size: 14px;
    line-height: 7px;
    letter-spacing: 0px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    padding-right: 80px;

    &:last-child {
      margin-bottom: 10px;
    }

    svg {
      margin-right: 9px;
      margin-top: 5px;
    }

    > div {
      flex: 1;
    }
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 16px 0 4px 0;
`;

const LinkWithIcon = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const PlanSelector: React.FC = () => {
  const track = useTrack();

  const { value: enablePricingPageUpdates } = useGate(FeatureFlag.EnablePricingPageUpdates);
  const { value: enableGmailDiscountTake50Coeff } = useGate(
    FeatureFlag.EnableGmailDiscountTake50coeff
  );

  const {
    billingMetadata,
    basePath,
    domain,
    selectedBillingInterval,
    setBillingInterval,
    free_trial_expiration_dt,
    free_trial_total_days,
    setDataSources,
    setPlanType,
    userSubscription,
    usersInCurrentPlan,
    updateSubscription,
    getUserSubscription,
    setUsers,
    domains_in_domain_family,
    setContactSupportInfoBox,
  } = useContext(BillingContext) as BContext;

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const platform = searchParams.get("platform");

  const history = useHistory();

  const subscriptionDetails = userSubscription?.subscription_details;

  const [showTrialDowngradeInfo, setShowTrialDowngradeInfo] = useState<boolean>(false);

  const [showInvalidStarterDowngrade, setShowInvalidStarterDowngrade] = useState<boolean>(false);

  const [showDowngradeConfirm, setShowDowngradeConfirm] = useState<"free" | "starter" | null>(null);

  const [showStarterDowngradePricingSummary, setShowStarterDowngradePricingSummary] =
    useState<boolean>(false);

  const [downgradeLoading, setDowngradeLoading] = useState<boolean>(false);

  const [newPriceSummary, setNewPriceSummary] = useState<null | PricingSummary>(null);

  const plan_type = subscriptionDetails?.plan_type;

  const onEnterprisePlanClick = () => {
    track("billing_plan_selected", {
      selected_frequency: selectedBillingInterval,
      selected_plan_type: "enterprise",
    });

    window.open("https://forms.default.com/959923", "_blank");
  };

  const getPlanData = (planType: SubscriptionPlanType) => {
    if (!billingMetadata) {
      return null;
    }
    const plan = billingMetadata.plans.find(
      p => p.plan_type === planType && p.billing_interval === selectedBillingInterval
    );

    return plan;
  };

  const planClicked = (clickedPlan: SubscriptionPlanType) => {
    if (clickedPlan === "starter") {
      if (plan_type === "pro") {
        return downgradeToStarterCheck();
      }

      track("billing_plan_selected", {
        selected_frequency: selectedBillingInterval,
        selected_plan_type: "starter",
      });

      setPlanType("starter");

      history.push({
        pathname: `${basePath}/customize`,
        search: window.location.search,
      });
    } else if (clickedPlan === "pro") {
      if (plan_type === "starter") {
        setBillingInterval(subscriptionDetails?.billing_interval ?? "annually");
        setDataSources(subscriptionDetails?.selected_data_source_types ?? []);
        setUsers(usersInCurrentPlan.map(u => u.user ?? {}));
      }

      track("billing_plan_selected", {
        selected_frequency: selectedBillingInterval,
        selected_plan_type: "pro",
      });

      setPlanType("pro");

      history.push({
        pathname: `${basePath}/customize`,
        search: window.location.search,
      });
    }
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);

    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Your plan will be downgraded to Starter");
      getUserSubscription();
    }
  };

  const downgradeToStarterCheck = () => {
    if (subscriptionDetails?.max_users && subscriptionDetails?.max_users > 1) {
      setShowInvalidStarterDowngrade(true);
    } else {
      setShowDowngradeConfirm("starter");
    }
  };

  const downgradeToStarterShowPricing = () => {
    const starterPlan = billingMetadata?.plans.find(
      plan =>
        plan.plan_type === "starter" &&
        plan.billing_interval === subscriptionDetails?.billing_interval
    );

    const planMonthlyPrice: number =
      billingMetadata?.plans.find(
        p => p.plan_type === "starter" && p.billing_interval === "monthly"
      )?.per_user_plan?.per_item_monthly_price_usd || 0;

    const planAnnualMonthlyPrice: number =
      billingMetadata?.plans.find(
        p => p.plan_type === "starter" && p.billing_interval === "annually"
      )?.per_user_plan?.per_item_monthly_price_usd || 0;

    const newPriceSummary = getPricingSummary(
      subscriptionDetails?.plan_type,
      subscriptionDetails?.billing_interval ?? "annually", // billing interval
      planMonthlyPrice, // monthly price for the plan (monthly interval)
      planAnnualMonthlyPrice, // monthly price for the plan (annual interval)
      starterPlan?.per_additional_data_source_addon?.per_item_monthly_price_usd || 0, // addtional data source cost
      1, // # of users
      subscriptionDetails?.max_data_source_types ?? 0, // # of data souce
      billingMetadata?.coupon_discount_percentage
    );

    setPlanType(subscriptionDetails?.plan_type ?? "pro");
    setBillingInterval(subscriptionDetails?.billing_interval ?? "annually");
    setNewPriceSummary(newPriceSummary);
    setShowStarterDowngradePricingSummary(true);
  };

  const downgradeToStarter = async () => {
    setDowngradeLoading(true);
    const starterPlan = billingMetadata?.plans.find(
      plan =>
        plan.plan_type === "starter" &&
        plan.billing_interval === subscriptionDetails?.billing_interval
    );

    await updateSubscriptionOnServer({
      plan: starterPlan,
      update_type: "modify_subscription",
      total_user_licenses: 1,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      selected_user_ids: usersInCurrentPlan.map(u => u.user?.user_id),
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });
    setShowStarterDowngradePricingSummary(false);
    setDowngradeLoading(false);

    track("billing_plan_changed", {
      from_plan_type: "starter",
      to_plan_type: "pro",
    });
  };

  const fractionalDaysLeft = free_trial_expiration_dt
    ? getFreeTrialNumFractionalDaysLeft(free_trial_expiration_dt)
    : null;
  const percent = 100 - ((fractionalDaysLeft ?? 0) / (free_trial_total_days ?? 0)) * 100;

  // Start the progress bar visually at 15% (instead of starting out empty if trial just started)
  const clampMinPercent = 50;
  const clampMaxPercent = 100;
  const scaledPercent = Math.max(clampMinPercent, Math.min(clampMaxPercent, percent));

  const DomainFamily = () => {
    const domains = domains_in_domain_family;
    if (domains.length === 1) {
      return <h3>Users from {domains[0]}</h3>;
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ marginBottom: "0px" }}>Users from your domains</h3>

          <Tooltip
            variant="light"
            getPopupContainer={triggerNode => triggerNode}
            title={
              <div>
                <Bold>Domains</Bold>
                <DomainFamilyList>
                  {domains.map(domain => (
                    <li>{domain}</li>
                  ))}
                </DomainFamilyList>
              </div>
            }
          >
            <span style={{ paddingLeft: "10px", marginTop: "5px" }}>
              <InfoCircleFilled style={{ color: COLORS.black25 }} />
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  const DowngradeWarning = (
    <Card className="yellow" noMargin>
      <PaperContent size="small">
        <Typography variant="body2" lineHeight="16px" fontWeight={600}>
          Downgrading your plan does NOT cancel your plan. To cancel your plan, please contact us at{" "}
          <a href="mailto:support@coefficient.io">support@coefficient.io</a>
        </Typography>
      </PaperContent>
    </Card>
  );

  const paidPlanTypes: Array<SubscriptionPlanType> = [
    SubscriptionPlanType.Enterprise,
    SubscriptionPlanType.Pro,
    SubscriptionPlanType.Plus,
    SubscriptionPlanType.Starter,
  ];

  const starterPlanPrice = getPlanData("starter")?.per_user_plan?.per_item_monthly_price_usd ?? 59;
  const proPlanPrice = getPlanData("pro")?.per_user_plan?.per_item_monthly_price_usd ?? 119;
  const shouldShowGmailDiscount = Boolean(domain === "gmail.com" && enableGmailDiscountTake50Coeff);

  const PerPlanDetailsV1 = (
    <SelectorWrap>
      <div className="column labels">
        <Title>
          <TitleRow>
            <Typography>
              <h2>Choose your plan</h2>
            </Typography>

            {platform === "excel_addon" ? (
              <Banner>
                <BannerContent>
                  <Excel height={28} width={28} />

                  <Typography fontWeight={600}>
                    Some features are not supported in Excel yet but are coming soon!
                  </Typography>
                </BannerContent>

                <BannerAction target="_blank" href="https://coefficient.io/pricing#excel-support">
                  Learn more
                </BannerAction>
              </Banner>
            ) : null}
          </TitleRow>

          {!subscriptionDetails || subscriptionDetails?.billing_interval === "monthly" ? (
            <>
              <SwitchContainer
                onClick={() => {
                  setBillingInterval(
                    selectedBillingInterval === "monthly" ? "annually" : "monthly"
                  );
                }}
              >
                <SwitchLabel active={selectedBillingInterval === "monthly"}>Monthly</SwitchLabel>

                <Switch active size="small" checked={selectedBillingInterval === "annually"} />

                <SwitchLabel active={selectedBillingInterval === "annually"}>Annual</SwitchLabel>
              </SwitchContainer>

              <Typography fontSize="12px" fontWeight={600} gutterBottom={3}>
                Save 17% annually
              </Typography>
            </>
          ) : null}

          <div className="desc">Simple, transparent pricing for teams of all sizes</div>
        </Title>

        <div style={{ paddingBottom: "20px" }}>
          <div className="row">Import Size</div>
          <div className="row">Data Sources</div>
          <div className="row">Auto Data Refresh</div>
          <div className="row">
            <Tooltip title="Data writebacks available for Salesforce, HubSpot, MySQL, and PostgreSQL">
              <span className="underline">Data Writebacks</span>
            </Tooltip>
          </div>
          <div className="row">Slack & Email Alerts</div>
          <div className="row">
            <Tooltip title="Share connections and imports. Plus, access team dashboards.">
              <span className="underline">Multi-User Support</span>
            </Tooltip>
          </div>
          <div className="row">Shared Connections</div>
          <div className="row">
            <Tooltip title="Access GPT in Sheets with GPT Copilot.">
              <span className="underline">OpenAI API Calls</span>
            </Tooltip>
          </div>
          <div className="row">Consolidated Billing</div>
          <div className="row">Admin Controls</div>
          <div className="row">Support</div>
        </div>
      </div>

      <div className="column plan starter">
        {plan_type === "starter" && <div className="planHeader">Current plan</div>}
        <div className={`planBox ${plan_type === "starter" ? "noTopCurve" : ""}`}>
          <div className="planDetails">
            <div className="planTitle">Starter</div>

            <div style={{ marginBottom: "10px" }}>
              <span className="planPrice">${starterPlanPrice}</span> / month
            </div>
          </div>

          <DEPRECATED_Button
            className={`ctaBtn ${plan_type === "starter" ? "selected" : ""}`}
            type="primary"
            onClick={() => planClicked("starter")}
          >
            {plan_type === "starter"
              ? "Current Plan"
              : `${plan_type === "pro" ? "Downgrade" : "Upgrade to Starter"}`}
          </DEPRECATED_Button>

          <div className="row">5,000 rows</div>

          <div className="row">
            <Tooltip
              title={`$${
                getPlanData("starter")?.per_additional_data_source_addon?.per_item_monthly_price_usd
              } per additional source`}
            >
              <span className="underline">&nbsp;1+&nbsp;</span>
            </Tooltip>
          </div>
          <div className="row">Daily</div>
          <div className="row">–</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">–</div>
          <div className="row">–</div>
          <div className="row">100,000/mo</div>
          <div className="row">–</div>
          <div className="row">–</div>
          <div className="row">Chat, Email</div>
        </div>
      </div>

      <div className="column plan pro">
        {(plan_type === "pro" || plan_type === "free_trial") && (
          <div className="planHeader">
            {plan_type === "free_trial" && fractionalDaysLeft === null && (
              <>
                You are on a Free Trial
                <br />
                of the Pro Plan
                <br />
                <a className="underline" href="#">
                  Learn more
                </a>
              </>
            )}

            {plan_type === "free_trial" &&
              fractionalDaysLeft !== null &&
              fractionalDaysLeft >= 0 && (
                <>
                  {Math.floor(fractionalDaysLeft)} day
                  {pluralize(Math.floor(fractionalDaysLeft))} left
                  <br />
                  in your free trial
                  <br />
                  <Progress
                    percent={scaledPercent}
                    showInfo={false}
                    size="small"
                    strokeColor={COLORS.yellow}
                  />
                </>
              )}

            {plan_type === "pro" && <>Current plan</>}
          </div>
        )}
        <div
          className={`planBox ${
            ["pro", "free_trial"].includes(plan_type ?? "") ? "noTopCurve" : ""
          }`}
        >
          <div className="planDetails">
            <div className="planTitle">Pro</div>
            <div style={{ marginBottom: "10px" }}>
              <span className="planPrice">${proPlanPrice}</span> <span>user / month</span>
            </div>
          </div>

          <DEPRECATED_Button
            className={`ctaBtn ${plan_type === "pro" ? "selected" : ""}`}
            type="primary"
            onClick={() => planClicked("pro")}
          >
            {plan_type === "pro" ? "Current Plan" : "Upgrade to Pro"}
          </DEPRECATED_Button>

          <div className="row">Unlimited</div>
          <div className="row">
            <Tooltip
              title={`$${
                getPlanData("pro")?.per_additional_data_source_addon?.per_item_monthly_price_usd
              } per additional source`}
            >
              <span className="underline">&nbsp;1+&nbsp;</span>
            </Tooltip>
          </div>
          <div className="row">Hourly</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">100,000/mo</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">–</div>
          <div className="row">Chat, Email</div>
        </div>
      </div>

      <div className="column plan enterprise">
        <div className={`planBox`}>
          <div className="planDetails">
            <div className="planTitle">Enterprise</div>
            <div
              style={{
                display: "flex",
                height: "60px",
                alignItems: "center",
              }}
            >
              <span className="planPrice">
                <CommentOutlined style={{ fontSize: "40px" }} />
              </span>{" "}
              <span style={{ marginLeft: "12px", lineHeight: "1.25" }}>
                volume discounts for teams
              </span>
            </div>
          </div>
          <DEPRECATED_Button className={`ctaBtn`} type="primary" onClick={onEnterprisePlanClick}>
            Contact Us
          </DEPRECATED_Button>
          <div className="row">Unlimited</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">100,000/mo</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">Dedicated</div>
        </div>
      </div>

      <div className="column otherUsers">
        <div className="userList">
          <DomainFamily />
          {billingMetadata?.users_from_domain.slice(0, 3).map((user, i) => (
            <div key={i} style={{ margin: "4px 0px" }}>
              {user.user?.first_name ?? ""} {user.user?.last_name ?? ""}{" "}
              <PlanTag planType={user.plan_type} />
            </div>
          ))}

          {billingMetadata?.users_from_domain && billingMetadata?.users_from_domain.length > 3 && (
            <Link to={{ pathname: `${basePath}/team`, search: window.location.search }}>
              and {billingMetadata?.users_from_domain.length - 3} more
            </Link>
          )}
        </div>

        <FAQSection />
      </div>
    </SelectorWrap>
  );

  const PerPlanDetailsV2 = (
    <SelectorWrapV2 shouldShowGmailDiscount={shouldShowGmailDiscount}>
      <div className="column labels">
        <Title>
          <TitleRow>
            <Typography>
              <h2>Choose your plan</h2>
            </Typography>

            {shouldShowGmailDiscount ? (
              <Banner style={{ backgroundColor: COLORS.yellow }}>
                <BannerContent>
                  <GiftIcon />
                  <Typography fontWeight={600}>
                    For a limited time, get 50% off your first year on Starter & Pro plans!
                  </Typography>
                </BannerContent>
              </Banner>
            ) : null}

            {platform === "excel_addon" ? (
              <Banner>
                <BannerContent>
                  <Excel height={28} width={28} />

                  <Typography fontWeight={600}>
                    Some features are not supported in Excel yet but are coming soon!
                  </Typography>
                </BannerContent>

                <BannerAction target="_blank" href="https://coefficient.io/pricing#excel-support">
                  Learn more
                </BannerAction>
              </Banner>
            ) : null}
          </TitleRow>

          {!subscriptionDetails || subscriptionDetails?.billing_interval === "monthly" ? (
            <>
              <SwitchContainer
                onClick={() => {
                  setBillingInterval(
                    selectedBillingInterval === "monthly" ? "annually" : "monthly"
                  );
                }}
              >
                <SwitchLabel active={selectedBillingInterval === "monthly"}>Monthly</SwitchLabel>

                <Switch active size="small" checked={selectedBillingInterval === "annually"} />

                <SwitchLabel active={selectedBillingInterval === "annually"}>Annual</SwitchLabel>
              </SwitchContainer>

              <Typography fontSize="12px" fontWeight={600} gutterBottom={3}>
                Save 17% annually
              </Typography>
            </>
          ) : null}

          <div className="desc">Simple, transparent pricing for teams of all sizes</div>
        </Title>

        <div style={{ paddingBottom: "20px" }}>
          <div className="row header">Import Data To Sheet</div>
          <div className="row">
            <FlexContainer>
              <Tooltip title="The number of distinct data source systems you can connect to. For example, Salesforce and Snowflake would be 2 sources.">
                <span className="underline">Data Sources</span>
              </Tooltip>

              <a
                href="https://coefficient.io/integrations"
                target="_blank"
                style={{ fontSize: 12, fontWeight: 700, marginTop: 1 }}
              >
                See all
              </a>
            </FlexContainer>
          </div>
          <div className="row">
            <Tooltip title="The maximum number of rows for importing is set individually for each import. You can create multiple imports, each with its own limit. Rows exceeding this limit will be excluded.">
              <span className="underline">Import Size</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="The monthly limit for refreshing imports includes both manual and scheduled runs and it's cumulative across all your created imports.">
              <span className="underline">Refresh Limit</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="The frequency at which you can schedule automated refreshes of your data imports.">
              <span className="underline">Auto Data Refresh</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Automated snapshots of your data that get copied into a new tab within the same spreadsheet.">
              <span className="underline">Data Snapshots</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="The number of distinct accounts you can connect within the same source. For example, if you use Instagram and have 3 different accounts. For many sources, you may only have 1 account.">
              <span className="underline">Accounts Per Source</span>
            </Tooltip>
          </div>

          <div className="row header">Export Data From Sheet</div>
          <div className="row">
            <Tooltip title="The maximum number of rows for exporting is set individually for each export. You can create multiple exports, each with its own limit. Rows exceeding this limit will be excluded.">
              <span className="underline">Export Size</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="The monthly limit for running exports includes both manual and scheduled runs and it's cumulative across all your created exports.">
              <span className="underline">Export Runs Limit</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="The frequency at which you can schedule automated refreshes of your data exports.">
              <span className="underline">Auto Data Export</span>
            </Tooltip>
          </div>

          <div className="row header">Data Alerts</div>
          <div className="row">
            <Tooltip title="The monthly limit on number of alerts sent via Slack and email.">
              <span className="underline">Slack & Email Alerts</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Send alerts dynamically using the email address column in a table of data.">
              <span className="underline">Dynamic Recipients</span>
            </Tooltip>
          </div>

          <div className="row header">AI Features</div>
          <div className="row">
            <LinkWithIcon href="https://coefficient.io/gpt-google-sheets" target="_blank">
              <span style={{ color: COLORS.black85, borderBottom: `1px solid ${COLORS.black45}` }}>
                On Sheet Functions
              </span>
              <NewWindow3 fill={COLORS.black45} />
            </LinkWithIcon>
          </div>
          <div className="row">
            <LinkWithIcon href="https://coefficient.io/ai" target="_blank">
              <span style={{ color: COLORS.black85, borderBottom: `1px solid ${COLORS.black45}` }}>
                SQL, Chart & Pivot Builders
              </span>
              <NewWindow3 fill={COLORS.black45} />
            </LinkWithIcon>
          </div>
          <div className="row">
            <Tooltip title="The monthly limit on number of OpenAI API calls across all AI features.">
              <span className="underline">OpenAI API Calls</span>
            </Tooltip>
          </div>

          <div className="row header">Collaboration & Admin</div>
          <div className="row">
            <Tooltip title="Allows multiple users to refresh your imports and collaborate in a common workspace.">
              <span className="underline">Multi-User Support</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Ability to share your data source connections with other users in your workspace.">
              <span className="underline">Shared Connections</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Ability to save and share reusable import templates with users in your workspace.">
              <span className="underline">Shared Import Templates</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Centralized admin controls and visibility across your workspace.">
              <span className="underline">Admin Controls</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Configure your own custom SSO login provider like Okta.">
              <span className="underline">Custom SSO</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="Manage billing for multiple users across your workspace.">
              <span className="underline">Consolidated Billing</span>
            </Tooltip>
          </div>
          <div className="row">
            <Tooltip title="We’ll work with you to go through a full security review process and MSA.">
              <span className="underline">Security Review & MSA</span>
            </Tooltip>
          </div>

          <div className="row header">Support</div>
          <div className="row">Help Center Access</div>
          <div className="row">Support Channel</div>
        </div>
      </div>

      <div className="column plan starter">
        {plan_type === "starter" && <div className="planHeader">Current plan</div>}
        <div className={`planBox ${plan_type === "starter" ? "noTopCurve" : ""}`}>
          <div className="planDetails">
            {shouldShowGmailDiscount ? (
              <React.Fragment>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                  <div className="planTitle">Starter</div>
                  <Tag
                    style={{
                      background: COLORS.yellow,
                      borderColor: COLORS.yellow,
                      fontWeight: "bold",
                      fontSize: "16px",
                      padding: "0 2px",
                      margin: 0,
                    }}
                  >
                    50% OFF
                  </Tag>
                </div>
                <div>
                  <span className="planPrice" style={{ marginRight: "6px" }}>
                    ${Math.floor(starterPlanPrice * 0.5).toFixed(0)}
                  </span>{" "}
                  / month
                </div>
                <div>
                  <del>Regular price: ${starterPlanPrice}</del>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="planTitle">Starter</div>
                <div style={{ marginBottom: "10px" }}>
                  <span className="planPrice">${starterPlanPrice}</span> / month
                </div>
              </React.Fragment>
            )}
          </div>

          <Button
            className={`ctaBtn ${plan_type === "starter" ? "selected" : ""}`}
            type="primary"
            onClick={() => planClicked("starter")}
          >
            {plan_type === "starter"
              ? "Current Plan"
              : `${plan_type === "pro" ? "Downgrade" : "Upgrade to Starter"}`}
          </Button>

          <div className="row header">&nbsp;</div>
          <div className="row">3</div>
          <div className="row">5,000 rows</div>
          <div className="row">500 refreshes / month</div>
          <div className="row">Daily</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">5</div>

          <div className="row header">&nbsp;</div>
          <div className="row">500 rows</div>
          <div className="row">500 runs / month</div>
          <div className="row">Daily</div>

          <div className="row header">&nbsp;</div>
          <div className="row">100 alerts / month</div>
          <div className="row">-</div>

          <div className="row header">&nbsp;</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">50K / month</div>

          <div className="row header">&nbsp;</div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>

          <div className="row header">&nbsp;</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">Chat, Email</div>
        </div>
      </div>

      <div className="column plan pro">
        {(plan_type === "pro" || plan_type === "free_trial") && (
          <div className="planHeader">
            {plan_type === "free_trial" && fractionalDaysLeft === null && (
              <>
                You are on a Free Trial
                <br />
                of the Pro Plan
                <br />
                <a className="underline" href="#">
                  Learn more
                </a>
              </>
            )}

            {plan_type === "free_trial" &&
              fractionalDaysLeft !== null &&
              fractionalDaysLeft >= 0 && (
                <>
                  {Math.floor(fractionalDaysLeft)} day
                  {pluralize(Math.floor(fractionalDaysLeft))} left
                  <br />
                  in your free trial
                  <br />
                  <Progress
                    percent={scaledPercent}
                    showInfo={false}
                    size="small"
                    strokeColor={COLORS.yellow}
                  />
                </>
              )}

            {plan_type === "pro" && <>Current plan</>}
          </div>
        )}
        <div
          className={`planBox ${
            ["pro", "free_trial"].includes(plan_type ?? "") ? "noTopCurve" : ""
          }`}
        >
          <div className="planDetails">
            {shouldShowGmailDiscount ? (
              <React.Fragment>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                  <div className="planTitle">Pro</div>
                  <Tag
                    style={{
                      background: COLORS.yellow,
                      borderColor: COLORS.yellow,
                      fontWeight: "bold",
                      fontSize: "16px",
                      padding: "0 2px",
                      margin: 0,
                    }}
                  >
                    50% OFF
                  </Tag>
                </div>
                <div>
                  <span className="planPrice" style={{ marginRight: "6px" }}>
                    ${Math.floor(proPlanPrice * 0.5).toFixed(0)}
                  </span>{" "}
                  <span>user / month</span>
                </div>
                <div>
                  <del>Regular price: ${proPlanPrice}</del>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="planTitle">Pro</div>
                <div style={{ marginBottom: "10px" }}>
                  <span className="planPrice">${proPlanPrice}</span> <span>user / month</span>
                </div>
              </React.Fragment>
            )}
          </div>

          <Button
            className={`ctaBtn ${plan_type === "pro" ? "selected" : ""}`}
            type="primary"
            onClick={() => planClicked("pro")}
          >
            {plan_type === "pro" ? "Current Plan" : "Upgrade to Pro"}
          </Button>

          <div className="row header">&nbsp;</div>
          <div className="row">6</div>
          <div className="row">Unlimited</div>
          <div className="row">5,000 refreshes / month</div>
          <div className="row">Hourly</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">10</div>

          <div className="row header">&nbsp;</div>
          <div className="row">Unlimited</div>
          <div className="row">5,000 runs / month</div>
          <div className="row">Hourly</div>

          <div className="row header">&nbsp;</div>
          <div className="row">300 alerts / month</div>
          <div className="row">
            <Tick />
          </div>

          <div className="row header">&nbsp;</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">100K / month</div>

          <div className="row header">&nbsp;</div>
          <div className="row">10 users max</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>
          <div className="row">-</div>

          <div className="row header">&nbsp;</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">Priority Chat, Email</div>
        </div>
      </div>

      <div className="column plan enterprise">
        <div className={`planBox`}>
          <div className="planDetails">
            <div className="planTitle">Enterprise</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                ...(shouldShowGmailDiscount ? { marginBottom: "38px" } : {}),
              }}
            >
              <span className="planPrice">
                <CommentOutlined style={{ fontSize: "32px" }} />
              </span>{" "}
              <span style={{ marginLeft: "12px", lineHeight: "1.25" }}>
                volume discounts for teams
              </span>
            </div>
          </div>

          <Button className={`ctaBtn`} type="primary" onClick={onEnterprisePlanClick}>
            Contact Us
          </Button>

          <div className="row header">&nbsp;</div>
          <div className="row">Custom</div>
          <div className="row">Unlimited</div>
          <div className="row">Unlimited</div>
          <div className="row">Hourly</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">Custom</div>

          <div className="row header">&nbsp;</div>
          <div className="row">Unlimited</div>
          <div className="row">Unlimited</div>
          <div className="row">Hourly</div>

          <div className="row header">&nbsp;</div>
          <div className="row">Unlimited</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row header">&nbsp;</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">100K / month</div>

          <div className="row header">&nbsp;</div>
          <div className="row">Custom</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">
            <Tick />
          </div>

          <div className="row header">&nbsp;</div>
          <div className="row">
            <Tick />
          </div>
          <div className="row">Dedicated</div>
        </div>
      </div>

      <div className="column otherUsers">
        <div className="userList">
          <DomainFamily />
          {billingMetadata?.users_from_domain.slice(0, 3).map((user, i) => (
            <div key={i} style={{ margin: "4px 0px" }}>
              {user.user?.first_name ?? ""} {user.user?.last_name ?? ""}{" "}
              <PlanTag planType={user.plan_type} />
            </div>
          ))}

          {billingMetadata?.users_from_domain && billingMetadata?.users_from_domain.length > 3 && (
            <Link to={{ pathname: `${basePath}/team`, search: window.location.search }}>
              and {billingMetadata?.users_from_domain.length - 3} more
            </Link>
          )}
        </div>

        <FAQSection />
      </div>
    </SelectorWrapV2>
  );

  return (
    <div>
      {searchParams.get("from") === "billing" ? (
        <Button
          noPadding
          type="link"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      ) : null}

      {enablePricingPageUpdates ? PerPlanDetailsV2 : PerPlanDetailsV1}

      <FreePlanBox>
        <h2 style={{ margin: 0 }}>Free Plan</h2>

        <div>
          Our free plan allows importing live data from any source into Google Sheets & Excel. Auto
          data refresh and other features are limited.{" "}
          <a href="https://coefficient.io/pricing" target="_blank">
            See free plan details
          </a>
        </div>

        {paidPlanTypes.includes(subscriptionDetails?.plan_type as SubscriptionPlanType) ? (
          <Button
            type="primary"
            onClick={() => {
              setContactSupportInfoBox({
                title: "Downgrade to Free",
                message: (
                  <>
                    To downgrade your plan to Free, please contact us at{" "}
                    <a href="mailto:support@coefficient.io" target="_blank">
                      support@coefficient.io
                    </a>
                    .
                  </>
                ),
              });
            }}
          >
            Downgrade
          </Button>
        ) : null}
      </FreePlanBox>

      <ConfirmBox
        title="🎉 Free plan!"
        visible={showTrialDowngradeInfo}
        okText="Got it"
        onOk={() => setShowTrialDowngradeInfo(false)}
        width={400}
      >
        You'll be downgraded to the Free Plan when your trial expires.
      </ConfirmBox>

      <ConfirmBox
        title="Invalid Change"
        visible={showInvalidStarterDowngrade}
        okText="Got it"
        onOk={() => setShowInvalidStarterDowngrade(false)}
        width={400}
      >
        You currently have multiple user licenses on your Pro plan. The Starter plan only supports
        one license.
      </ConfirmBox>

      <ConfirmBox
        title="Are you sure?"
        confirmLoading={downgradeLoading}
        visible={showDowngradeConfirm !== null}
        okText="Yes, Proceed"
        cancelText="Cancel"
        onCancel={() => setShowDowngradeConfirm(null)}
        onOk={() => {
          if (showDowngradeConfirm === "starter") {
            downgradeToStarterShowPricing();
            setShowDowngradeConfirm(null);
          }
        }}
        width={400}
      >
        <>
          <p>
            The Starter plan is only meant for individuals so you will not be able to add any users.
          </p>

          <p>You will lose the following features:</p>

          <ConfirmBoxList>
            <li>
              <CloseCircleFilled /> Hourly Auto Data Refresh
            </li>
            <li>
              <CloseCircleFilled /> Scheduled Bulk Write-Back
            </li>
            <li>
              <CloseCircleFilled /> Dynamic Recipient Data Alerts
            </li>
          </ConfirmBoxList>

          {DowngradeWarning}
        </>
      </ConfirmBox>

      <ConfirmBox
        confirmLoading={downgradeLoading}
        title="Confirm downgrade"
        visible={showStarterDowngradePricingSummary}
        okText="Confirm Change"
        cancelText="Cancel"
        onCancel={() => setShowStarterDowngradePricingSummary(false)}
        onOk={() => downgradeToStarter()}
        width={400}
      >
        <>
          <p>You're changing from Pro to Starter.</p>

          <p>
            Your current billing period ends{" "}
            {moment(subscriptionDetails?.next_billing_cycle_dt).format("MMM DD, YYYY")}. You will
            then be downgraded to the Starter plan
          </p>

          <PricingSummaryWrap>
            <h3>Starter Plan</h3>
            {newPriceSummary && (
              <PriceSummaryProrated
                editType="downgrade-to-starter"
                newPriceSummary={newPriceSummary}
              />
            )}
          </PricingSummaryWrap>

          {DowngradeWarning}
        </>
      </ConfirmBox>
    </div>
  );
};
