import { ExclamationCircleFilled } from "@ant-design/icons";
import { SubscriptionScheduledChangeType } from "@coeff/api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { Alert, CardIcon, ConfirmBox, Typography, UserList } from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS, IMPORT_TYPE_NAMES } from "../../../constants";
import { BContext } from "../../../types";
import {
  numberWithCommas,
  planNameFromType,
  pluralize,
  roundToHalf,
  useTrack,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { FAQSection } from "../components";

const Wrap = styled.div`
  color: ${COLORS.black85};

  .mainTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
`;

const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AlertMsg = styled(Alert)`
  margin-bottom: 20px;
  border: none;

  &.ant-alert-error {
    .updatePaymentLink {
      position: absolute;
      right: 16px;
      top: 25px;
      font-weight: bold;
    }
  }

  &.ant-alert-success {
    font-weight: 600;
    background-color: ${COLORS.seafoamBg};

    .ant-alert-message {
      color: ${COLORS.seafoam};
    }
  }
`;

const Section = styled.div`
  margin-bottom: 20px;

  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-style: dashed;
    text-underline-thickness: 1px;
    cursor: pointer;
  }

  .assignUsers {
    .ant-checkbox-wrapper {
      display: none;
    }

    .email {
      color: ${COLORS.coeblue4};
    }

    &:hover {
      border-color: ${COLORS.black2};
    }
  }
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Last4Digits = styled.span`
  position: relative;
  top: -5px;
  margin-left: 5px;
`;

export const SubscriptionInfo = () => {
  const track = useTrack();

  const {
    basePath,
    currentUserEmail,
    userSubscription,

    plan_type,
    due_invoices_count,
    due_invoice_error_type,
    usersInCurrentPlan,
    otherUsersIndomain,
    currentUserPlanManager,
    setContactSupportInfoBox,
  } = useContext(BillingContext) as BContext;

  const history = useHistory();

  const [showManagerPrompt, setShowManagerPrompt] = useState<boolean>(false);

  useEffect(() => {
    if (!userSubscription) {
      return history.push({
        pathname: `${basePath}/plans`,
        search: window.location.search,
      });
    }
  }, [plan_type]);

  const planName = planNameFromType(plan_type || "");

  const manageTeamClick = () => {
    track("billing_manage_team_clicked");
    history.push({
      pathname: `${basePath}/team/manage`,
      search: window.location.search,
    });
  };

  const subscriptionDetails = userSubscription?.subscription_details || null;

  const additionalUsers =
    (userSubscription?.subscription_details?.max_users ?? 0) -
    (userSubscription?.invite_users ?? []).length -
    usersInCurrentPlan.length;

  return (
    <Wrap>
      {!!due_invoices_count && due_invoices_count > 0 && due_invoice_error_type === "other" && (
        <AlertMsg
          message="Payment failed"
          description={
            <>
              Your last payment of {subscriptionDetails?.recurring_amount_usd} couldn't be processed
              to the card ending with ...{subscriptionDetails?.cc_last_4_digits}
              <Link
                className="updatePaymentLink"
                to={{ pathname: `${basePath}/billing`, search: window.location.search }}
                onClick={e => {
                  if (currentUserPlanManager?.email !== currentUserEmail) {
                    e.preventDefault();
                    setShowManagerPrompt(true);
                  }
                }}
              >
                Update Payment Method
              </Link>
            </>
          }
          type="error"
          showIcon
          icon={<ExclamationCircleFilled />}
        />
      )}

      {!!due_invoices_count &&
        due_invoices_count > 0 &&
        due_invoice_error_type === "no_payment_method" && (
          <AlertMsg
            message="Please add a payment method"
            description={
              <>
                Your subscription is not active until you add a payment method.
                <Link
                  className="updatePaymentLink"
                  to={{ pathname: `${basePath}/billing`, search: window.location.search }}
                  onClick={e => {
                    if (currentUserPlanManager?.email !== currentUserEmail) {
                      e.preventDefault();
                      setShowManagerPrompt(true);
                    }
                  }}
                >
                  Add Payment Method
                </Link>
              </>
            }
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
        )}

      {!!due_invoices_count &&
        due_invoices_count > 0 &&
        due_invoice_error_type === "pending_offline_payment" && (
          <AlertMsg
            message="Unpaid invoice"
            description={
              <>
                Your last invoice has not been marked as paid yet. Please contact support to resolve
                this.
                <a
                  href="mailto:support@coefficient.io"
                  className="updatePaymentLink"
                  target="_blank"
                >
                  Contact Support
                </a>
              </>
            }
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
        )}

      {subscriptionDetails?.has_scheduled_changes &&
        subscriptionDetails?.scheduled_change_type === "downgrade_to_starter" && (
          <AlertMsg
            type="warning"
            description={
              <Typography fontWeight={600}>
                Your plan will be downgraded to Starter on{" "}
                {moment(subscriptionDetails?.next_billing_cycle_dt)
                  .add(1, "day")
                  .format("MMM DD, YYYY")}
              </Typography>
            }
          />
        )}

      {subscriptionDetails?.has_scheduled_changes &&
      subscriptionDetails.scheduled_change_type ===
        SubscriptionScheduledChangeType.DowngradeToMonthlyBilling ? (
        <AlertMsg
          type="warning"
          message={
            <Typography fontWeight={600}>
              Your billing frequency will be changed to monthly starting on{" "}
              {moment(subscriptionDetails?.next_billing_cycle_dt)
                .add(1, "day")
                .format("MMM DD, YYYY")}
            </Typography>
          }
        />
      ) : null}

      <div className="mainTitle">You're on the {planName} Plan</div>

      <InnerWrap>
        <div style={{ width: "60%" }}>
          {usersInCurrentPlan.length > 0 && (
            <Section>
              <TitleSection>
                <div className="sectionTitle">
                  {usersInCurrentPlan.length} {planName} User
                  {pluralize(usersInCurrentPlan.length)}
                </div>
                <Link
                  to={{ pathname: `${basePath}/team/manage`, search: window.location.search }}
                  onClick={e => {
                    track("billing_manage_team_clicked");
                    if (currentUserPlanManager?.email !== currentUserEmail) {
                      e.preventDefault();
                      setShowManagerPrompt(true);
                    }
                  }}
                >
                  Manage team & user licenses
                </Link>
              </TitleSection>

              {usersInCurrentPlan.map((user, i) => (
                <UserList
                  key={i}
                  avatarColor={avatarColors[i % avatarColors.length]}
                  name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                  email={user.user?.email || ""}
                  userId={user.user?.user_id || ""}
                  plan={user.plan_type}
                />
              ))}

              {userSubscription?.invite_users?.map((user, i) => (
                <UserList
                  key={i}
                  avatarColor={avatarColors[(i + usersInCurrentPlan.length) % avatarColors.length]}
                  name={
                    user.user?.first_name || user.user?.last_name
                      ? `${user.user?.first_name || ""} ${user.user?.last_name || ""}`
                      : user.user?.email ?? ""
                  }
                  email={user.user?.email || ""}
                  userId={user.user?.user_id || ""}
                  plan={user.plan_type}
                />
              ))}

              {additionalUsers > 0 && (
                <UserList
                  className="assignUsers"
                  avatarColor={COLORS.seafoam}
                  avatarIcon={"+" + additionalUsers}
                  name={additionalUsers + " Unassigned"}
                  email={"Assign users to Pro"}
                  userId={""}
                  isSelectable={true}
                  toggleUser={manageTeamClick}
                />
              )}
            </Section>
          )}

          {otherUsersIndomain.length > 0 && (
            <Section>
              <TitleSection>
                <div className="sectionTitle">
                  {usersInCurrentPlan.length > 0 ? `Other users` : "Users"}
                </div>
                {usersInCurrentPlan.length <= 0 && (
                  <Link
                    to={{ pathname: `${basePath}/team/manage`, search: window.location.search }}
                    onClick={e => {
                      if (
                        currentUserPlanManager &&
                        currentUserPlanManager?.email !== currentUserEmail
                      ) {
                        e.preventDefault();
                        setShowManagerPrompt(true);
                      }
                    }}
                  >
                    Manage team & user licenses
                  </Link>
                )}
              </TitleSection>
              {otherUsersIndomain
                .filter(u => u.plan_type)
                .map((user, i) => (
                  <UserList
                    key={i}
                    avatarColor={
                      avatarColors[
                        (i +
                          usersInCurrentPlan.length +
                          (userSubscription?.invite_users?.length ?? 0)) %
                          avatarColors.length
                      ]
                    }
                    name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                    email={user.user?.email || ""}
                    userId={user.user?.user_id || ""}
                    plan={user.plan_type}
                    footer={
                      <div style={{ color: COLORS.black45 }}>
                        {user.managed_by_user
                          ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                              user.managed_by_user.last_name || ""
                            }`
                          : ""}
                      </div>
                    }
                  />
                ))}
              {otherUsersIndomain
                .filter(u => !u.plan_type)
                .map((user, i) => (
                  <UserList
                    key={i}
                    avatarColor={
                      avatarColors[
                        (i +
                          usersInCurrentPlan.length +
                          (userSubscription?.invite_users?.length ?? 0)) %
                          avatarColors.length
                      ]
                    }
                    name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
                    email={user.user?.email || ""}
                    userId={user.user?.user_id || ""}
                    plan={user.plan_type}
                    footer={
                      <div style={{ color: COLORS.black45 }}>
                        {user.managed_by_user
                          ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                              user.managed_by_user.last_name || ""
                            }`
                          : ""}
                      </div>
                    }
                  />
                ))}
            </Section>
          )}
        </div>

        <div style={{ width: "35%" }}>
          {subscriptionDetails?.max_data_source_types && (
            <Section>
              <div className="sectionTitle">
                {subscriptionDetails?.max_data_source_types} Data Source
                {pluralize(subscriptionDetails?.max_data_source_types)}
              </div>
              <div>
                {subscriptionDetails.selected_data_source_types?.map(
                  (ds, i) => (i > 0 ? ", " : "") + IMPORT_TYPE_NAMES[ds]
                )}
              </div>

              <div>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setContactSupportInfoBox({ title: "Manage data sources" });
                    track("billing_data_source_change_clicked");
                  }}
                >
                  Manage
                </a>
              </div>
            </Section>
          )}

          {subscriptionDetails && (
            <Section>
              <div className="sectionTitle">Billing</div>
              {subscriptionDetails?.cc_type && (
                <div>
                  <CardIcon type={subscriptionDetails?.cc_type || ""} />{" "}
                  <Last4Digits>...{subscriptionDetails?.cc_last_4_digits}</Last4Digits>
                </div>
              )}

              {subscriptionDetails.recurring_amount_usd && (
                <div>
                  You are paying $
                  {numberWithCommas(roundToHalf(subscriptionDetails.recurring_amount_usd))} per{" "}
                  {subscriptionDetails.billing_interval === "annually" ? "year" : "month"}
                </div>
              )}

              {subscriptionDetails.has_scheduled_changes &&
                subscriptionDetails?.scheduled_change_type === "cancel_subscription" && (
                  <div>
                    You will be downgraded to the Free plan on{" "}
                    {moment(subscriptionDetails?.next_billing_cycle_dt)
                      .add(1, "day")
                      .format("MMM DD, YYYY")}
                  </div>
                )}

              {subscriptionDetails.has_scheduled_changes &&
                subscriptionDetails?.scheduled_change_type === "downgrade_to_starter" && (
                  <div>
                    Your plan will be downgraded to Starter on{" "}
                    {moment(subscriptionDetails?.next_billing_cycle_dt)
                      .add(1, "day")
                      .format("MMM DD, YYYY")}
                  </div>
                )}

              {!subscriptionDetails.has_scheduled_changes ||
                (subscriptionDetails?.scheduled_change_type === "downgrade_to_monthly_billing" && (
                  <div>
                    Your next bill will be on{" "}
                    {moment(subscriptionDetails?.next_billing_cycle_dt).format("MMM DD, YYYY")}
                    {subscriptionDetails?.scheduled_change_type ===
                      "downgrade_to_monthly_billing" && " (monthly)"}
                  </div>
                ))}

              <div>
                <Link
                  to={{ pathname: `${basePath}/billing`, search: window.location.search }}
                  onClick={e => {
                    if (currentUserPlanManager?.email !== currentUserEmail) {
                      e.preventDefault();
                      setShowManagerPrompt(true);
                    }
                    track("billing_settings_clicked");
                  }}
                >
                  Plan and Billing Settings
                </Link>
              </div>
            </Section>
          )}

          <FAQSection />
        </div>
      </InnerWrap>

      <ConfirmBox
        title={
          currentUserPlanManager
            ? `${planNameFromType(subscriptionDetails?.plan_type ?? "")} plan managed by ${
                currentUserPlanManager?.first_name
              } ${currentUserPlanManager?.last_name}`
            : "Please assign at least one user"
        }
        visible={showManagerPrompt}
        onOk={() => setShowManagerPrompt(false)}
        okText="Got it"
        width={340}
      >
        {currentUserPlanManager
          ? `Please reach out to ${
              currentUserPlanManager?.first_name ?? ""
            } to make any changes to the
        plan or to add/remove user licenses.`
          : "Please assign at least one user before modifying the plan and billing settings"}
      </ConfirmBox>
    </Wrap>
  );
};
