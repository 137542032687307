import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { ConfirmBox, FlexRow, InputSearch, Typography, UserList } from "../../../components";
import { AVATAR_COLORS, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import { planNameFromType, pluralize, useTrack } from "../../../utils";
import { BillingContext } from "../Billing";

const ScrollList = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

export const AssignUserToLicenseModal: React.FC<Props> = ({ open, onClose }) => {
  const track = useTrack();

  const {
    getUserSubscription,
    userSubscription,
    updateSubscription,
    currentUserPlanManager,
    billingMetadata,
    usersInCurrentPlan,
    otherUsersIndomain,
  } = useContext(BillingContext) as BContext;

  const [usersToAssignIds, setUsersToAssignIds] = useState<Array<string>>([]);

  const usersToAssignIdsSet = new Set(usersToAssignIds);

  const [query, setQuery] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(false);
    setUsersToAssignIds([]);
    setQuery("");
  }, [open]);

  let usersInDomain = [...(billingMetadata?.users_from_domain ?? [])];

  usersInDomain = usersInDomain.sort((a, b) => {
    const bName = `${b.user.first_name} ${b.user.last_name}`;
    const aName = `${a.user.first_name} ${a.user.last_name}`;

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  const filteredUsersInDomain = usersInDomain.filter(u => {
    if (query === "") {
      return true;
    }

    return (
      u.user.email.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.first_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      (u.user.last_name ?? "").toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
  });

  const subscriptionDetails = userSubscription?.subscription_details;
  const planType = subscriptionDetails?.plan_type || "";

  const currentPlan = billingMetadata?.plans.find(
    plan =>
      plan.plan_type === planType && plan.billing_interval === subscriptionDetails?.billing_interval
  );

  const usersIdInCurrentPlanSet = new Set(usersInCurrentPlan.map(u => u.user.user_id));

  const upgradeUsers = async () => {
    setIsLoading(true);
    const selectedUsers = usersInCurrentPlan.map(u => u.user?.user_id);

    usersToAssignIds.forEach(id => {
      selectedUsers.push(id);
    });

    await updateSubscriptionOnServer({
      plan: currentPlan,
      update_type: "reassignment",
      invite_user_emails: (userSubscription?.invite_users ?? []).map(u => u.user?.email),
      selected_user_ids: selectedUsers,
      selected_data_source_types: subscriptionDetails?.selected_data_source_types,
      coupon_discount_percentage: billingMetadata?.coupon_discount_percentage,
    });
    track("billing_manage_team_user_added", {
      event_from: "user_list",
      added_users: otherUsersIndomain
        .filter(u => usersToAssignIdsSet.has(u.user?.user_id!))
        .map(u => u.user?.email),
    });

    onClose();
  };

  const updateSubscriptionOnServer = async body => {
    const response = await updateSubscription(body);
    if (!response.is_success) {
      message.error(response.error_msg || "Uh-oh. Something went wrong. Please try again");
    } else {
      message.success("Your subscription has been updated");
      getUserSubscription();
    }
  };

  const subscribedAdditionalUserSeats =
    (subscriptionDetails?.max_users ?? 0) -
    (userSubscription?.invite_users ?? []).length -
    usersInCurrentPlan.length;

  return (
    <ConfirmBox
      title={`Assign user to license`}
      visible={open}
      okText={`Confirm Change`}
      okButtonProps={{
        disabled: usersToAssignIds.length === 0 || isLoading,
      }}
      cancelText={`Cancel`}
      onOk={async () => {
        await upgradeUsers();
      }}
      onCancel={() => {
        onClose();
      }}
      width={800}
      confirmLoading={isLoading}
    >
      <FlexRow style={{ justifyContent: "space-between", marginBottom: "16px" }}>
        <Typography fontWeight={600} variant="body1">
          {`${filteredUsersInDomain.length} Available user${pluralize(
            filteredUsersInDomain.length
          )} ${query === "" ? `in your workspace` : `matching search`}`}
        </Typography>
        <InputSearch
          style={{ width: "50%" }}
          value={query}
          onChange={e => setQuery(e.target.value)}
          autoFocus
        />
      </FlexRow>
      <ScrollList>
        {filteredUsersInDomain.map((user, i) => (
          <UserList
            key={i}
            className="white"
            avatarColor={AVATAR_COLORS[i % AVATAR_COLORS.length]}
            name={`${user.user?.first_name || ""} ${user.user?.last_name || ""}`}
            email={user.user?.email || ""}
            userId={user.user?.user_id || ""}
            isSelectable={true}
            plan={user.plan_type}
            selected={
              usersToAssignIdsSet.has(user.user.user_id!) ||
              usersIdInCurrentPlanSet.has(user.user.user_id)
            }
            disabled={
              (user.plan_type &&
                user.managed_by_user?.user_id !== currentUserPlanManager?.user_id) ||
              usersIdInCurrentPlanSet.has(user.user.user_id)
            }
            tooltip={
              usersIdInCurrentPlanSet.has(user.user.user_id)
                ? `User is already assigned to this plan`
                : user.managed_by_user && user.plan_type
                ? `User is already on ${planNameFromType(user.plan_type)} plan`
                : undefined
            }
            toggleUser={userId => {
              if (usersToAssignIdsSet.has(userId)) {
                setUsersToAssignIds(usersToAssignIds.filter(id => id !== userId));
              } else {
                if (usersToAssignIds.length > subscribedAdditionalUserSeats) {
                  message.error(
                    `You only have ${subscribedAdditionalUserSeats} additional seat in your plan.`
                  );
                } else {
                  setUsersToAssignIds([...usersToAssignIds, userId]);
                }
              }
            }}
            footer={
              <FlexRow style={{ justifyContent: "flex-end" }}>
                <Typography color="textSecondary" variant="body2">
                  {user.managed_by_user
                    ? `Plan managed by ${user.managed_by_user.first_name || ""} ${
                        user.managed_by_user.last_name || ""
                      }`
                    : ""}
                </Typography>
              </FlexRow>
            }
          />
        ))}
        {filteredUsersInDomain.length === 0 && (
          <Typography color="textSecondary">No Users found...</Typography>
        )}
      </ScrollList>
    </ConfirmBox>
  );
};
